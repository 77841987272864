export const UCARE_CDN_URL = "https://ucarecdn.com";

export function convertTimezoneToGMTFormat(timezone: {
  name: string;
  utcOffset: number;
}): string {
  const offsetInMinutes = timezone.utcOffset;
  const hours = Math.abs(Math.floor(offsetInMinutes / 60));
  const minutes = Math.abs(offsetInMinutes % 60);
  const sign = offsetInMinutes >= 0 ? "+" : "-";
  return `GMT${sign}${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

export const isUcareFile = (url = ""): boolean => !!url.match(/ucarecdn/);

export const reformat = (
  url = "",
  ext = "-/preview/600x600/",
  blurred = false
) => {
  if (!url || url === "") {
    return;
  }

  const isUcare = isUcareFile(url);
  const uuid = (/((\w{4,12}-?)){5}/.exec(url) || [])[0];
  if (!(isUcare && uuid)) {
    return url;
  }

  let finalExt = ext.replace(/^\/|\/$/, "");
  if (blurred) {
    finalExt += "/-/blur/100/";
  }

  return `${UCARE_CDN_URL}/${uuid}/${finalExt}/`;
};

export const testImage = (url: string, imageNotFound: () => void) => {
  const imageTester = new Image();
  imageTester.addEventListener("error", imageNotFound, { once: true });
  imageTester.src = url;
};
