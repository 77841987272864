import { useMutation, useQueryClient } from "@tanstack/react-query";
import { InvitationSource } from "models/Account";
import { addAccountCollaborator } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../../keys";
import { Role } from "configs/role";

export interface useAddAccountCollaboratorInput {
  accountId: string;
  email: string;
  firstName: string;
  invitedFrom: InvitationSource;
  lastName: string;
  role?: Role;
  skipEmail?: boolean;
}

export const useAddAccountCollaborator = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accountId,
      email,
      firstName,
      invitedFrom,
      lastName,
      role = Role.WorkspaceMember,
      skipEmail,
    }: useAddAccountCollaboratorInput) =>
      addAccountCollaborator(
        authStore,
        accountId!,
        {
          email,
          firstName,
          lastName,
          role,
          skipEmail,
        },
        { invitedFrom }
      ),
    onError: (error) =>
      setApiErrorToast(error, "Unable to invite collaborator"),
    onSuccess: (_, { accountId }) => {
      queryClient.invalidateQueries(
        queryKeys.accounts.collaborators({ context: accountId }).queryKey
      );
    },
  });
};
