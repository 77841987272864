import { lazy } from "react";
import {
  ClientSettingsProfileLocation,
  ClientSettingsBaseLocation,
  ClientSettingsBillingLocation,
  ClientSettingsCollaboratorsLocation,
  ClientSettingsWorkspaceLocation,
  ClientSettingsTokensLocation,
} from "Locations";
import { CustomRouteProps } from "Routes";
import rewardsStoreLoader from "../loaders/rewardsStoreLoader";

const ClientSettingsProfile = lazy(
  () => import("../views/ClientSettings/ProfileSettings")
);
const ClientSettingsTokens = lazy(
  () => import("../views/ClientSettings/TokenSettings")
);
const ClientSettingsBilling = lazy(
  () => import("../views/ClientSettings/BillingSettings")
);
const ClientSettingsCollaborators = lazy(
  () => import("../views/ClientSettings/CollaboratorSettings")
);
const ClientSettingsWorkspace = lazy(
  () => import("../views/ClientSettings/WorkspaceSettings")
);

export const ClientSettingsRoutes: CustomRouteProps[] = [
  {
    component: ClientSettingsProfile,
    path: ClientSettingsProfileLocation,
    title: "Profile Settings",
    withAuth: true,
  },
  {
    component: ClientSettingsTokens,
    path: ClientSettingsTokensLocation,
    title: "Build Token Settings",
    withAuth: true,
    dataLoader: rewardsStoreLoader,
    requireAccount: true,
  },
  {
    component: ClientSettingsBilling,
    path: ClientSettingsBillingLocation,
    title: "Billing Settings",
    withAuth: true,
    requireAccount: true,
  },
  {
    component: ClientSettingsWorkspace,
    path: ClientSettingsWorkspaceLocation,
    title: "Workspace Settings",
    withAuth: true,
    requireAccount: true,
  },
  {
    component: ClientSettingsCollaborators,
    path: ClientSettingsCollaboratorsLocation,
    title: "Collaborator Settings",
    withAuth: true,
    requireAccount: true,
  },
  {
    path: ClientSettingsBaseLocation,
    redirectTo: ClientSettingsProfileLocation,
    withAuth: true,
    requireAccount: true,
    dataLoader: rewardsStoreLoader,
  },
];
