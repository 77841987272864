import { RoleCategoryId } from "@a_team/models/dist/RoleCategory";
import { useQueries } from "@tanstack/react-query";
import queryKeys from "queries/keys";
import { getRelevantSkills } from "services/missionSpec";
import { useRootStore } from "store";

export function useRelevantSkills(cids: Array<RoleCategoryId>) {
  const { authStore } = useRootStore();

  return useQueries({
    queries: cids.map((cid) => ({
      queryKey: queryKeys.talentCategories.list({ cid }).queryKey,
      queryFn: async () => {
        const relevantSkills = await getRelevantSkills(authStore, null, cid);
        return {
          [cid]: relevantSkills,
        };
      },
      enabled: authStore.isLoggedIn,
      onError: (error: unknown) =>
        console.error(`Failed to load relevant skills for ${cid}`, error),
    })),
  });
}
