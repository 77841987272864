/** @jsxImportSource @emotion/react */

import { DEFAULT_TOAST_DURATION } from "components/ToastProvider";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useIsMobile } from "hooks/useIsMobile";
import { observer } from "mobx-react";
import { FC } from "react";
import { Slide, ToastContainer } from "react-toastify";
import { useRootStore } from "store";

export interface CustomToastProviderProps {
  containerId: string;
}

const getCSSRules: CSSRulesResolver<{ sidebarOpen: boolean }> = ({
  sidebarOpen,
  breakpoints,
}) => ({
  toastContainer: {
    padding: 0,
    background: "transparent",
    transition: "margin-left 0.3s ease-in-out",
    borderRadius: 0,
    width: "100%",
    maxWidth: 400,
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: "auto",
      bottom: 16,
      maxWidth: "initial",
      marginLeft: sidebarOpen ? 132 : 32,
    },
  },
});

const CustomToastProvider: FC<CustomToastProviderProps> = ({ containerId }) => {
  const {
    uiStore: { sidebarOpen },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules, { sidebarOpen });
  const isMobile = useIsMobile();

  return (
    <ToastContainer
      css={styles.toastContainer}
      enableMultiContainer
      limit={1}
      toastStyle={{
        borderRadius: isMobile ? 0 : undefined,
        background: "transparent",
      }}
      bodyStyle={{
        padding: 0,
        background: "transparent",
      }}
      containerId={containerId}
      position="bottom-right"
      hideProgressBar
      pauseOnFocusLoss
      pauseOnHover
      autoClose={DEFAULT_TOAST_DURATION}
      transition={Slide}
    />
  );
};

export default observer(CustomToastProvider);
