/** @jsxImportSource @emotion/react */

import { BookInterviewButton } from "components/BookInterviewButton";
import { BuilderProfileShareModal } from "components/BuilderProfileShareModal";
import FeatureFlagComponent from "components/FeatureFlag";
import { StarButton } from "components/StarButton";
import { Flags } from "configs/featureFlags";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ClientSignalBuilder, ClientSignalContext } from "models/ClientSignal";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Flex, FlexCenter } from "views/Builder/components/Flex";
import { HideButton } from "views/Builder/components/HideButton";
import { ShareBuilderButton } from "views/Builder/components/ShareBuilderButton";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  container: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
});

export const MobileStarButton = ({
  builder,
  embedded = false,
  context,
}: {
  builder?: ClientSignalBuilder;
  embedded?: boolean;
  context?: ClientSignalContext;
}) => {
  if (!builder) {
    return <Skeleton />;
  }

  const styles = useCSSRulesWithTheme(getCSSRules);

  const [showShareProfileModal, setShowShareProfileModal] = useState(false);

  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useUserProfileByUserId(builder.uid);

  if (isLoadingUserProfile || !userProfile) {
    return <Skeleton />;
  }

  return (
    <div css={styles.container}>
      <Flex
        style={{
          backgroundColor: "#FFFFFF",
          bottom: 0,
          boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
          padding: "15px",
          position: "fixed",
          width: "100%",
          justifyContent: "flex-end",
          gap: 8,
          flexWrap: "nowrap",
        }}
      >
        <FlexCenter>
          {!embedded && <HideButton userId={builder.uid} />}
        </FlexCenter>
        <FeatureFlagComponent on name={Flags.DirectInterviewBooking}>
          <BookInterviewButton
            user={userProfile}
            disabled={
              userProfile.isUsingSharedCalendar || !userProfile.calComUserId
            }
          />
        </FeatureFlagComponent>
        <div>
          <StarButton builder={builder} context={context} />
        </div>
        <div>
          <ShareBuilderButton
            user={userProfile}
            size="small"
            onShare={() => setShowShareProfileModal(true)}
          />
        </div>
      </Flex>
      <BuilderProfileShareModal
        isOpen={showShareProfileModal}
        onClose={() => setShowShareProfileModal(false)}
        builder={userProfile}
      />
    </div>
  );
};
