/** @jsxImportSource @emotion/react */

import { GradientStroke, Tooltip, Typography } from "@a_team/ui-components";
import { useSidebarColorTheme } from "components/Sidebar/context";
import { Flags } from "configs/featureFlags";
import { ColorTheme } from "helpers/styles";
import useUserRolesFromAccount from "hooks/access/useUserRolesFromAccount";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { observer } from "mobx-react";
import { FunctionComponent } from "react";
import { useRootStore } from "store";
import { imageFit } from "@styles/index";

const getCSSRules: CSSRulesResolver<{ colorTheme?: ColorTheme }> = ({
  colors,
  breakpoints,
  colorTheme,
}) => ({
  accountMenu: {
    whiteSpace: "nowrap",
    padding: "13px 20px",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.Grey[100],
    ...(colorTheme === ColorTheme.DARK && {
      backgroundColor: colors.Grey[800],
      color: colors.Grey[0],
    }),
    // Hides/shows the gradient bottom border div
    ".mobile-closed & div:last-child": {
      display: "none",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "block",
      },
    },
  },
  avatar: {
    flexShrink: "0",
    width: "24px",
    height: "24px",
    backgroundColor: colors.Hannibal[600],
    marginRight: "20px",
    borderRadius: "9px",
    overflow: "hidden",
    "& img": {
      ...imageFit,
    },
  },
  caption: {
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    flexDirection: "column",
  },
  menu: {
    marginLeft: "auto",
  },
});

const ProfileImage = ({
  profilePictureURL,
  displayName = "User profile picture",
}: {
  profilePictureURL: string;
  displayName: string;
}) => {
  const { colorTheme } = useSidebarColorTheme();
  const styles = useCSSRulesWithTheme(getCSSRules, { colorTheme });
  const avatar = useBuilderAvatar(profilePictureURL || "");

  if (!profilePictureURL) {
    return null;
  }

  return (
    <div css={styles.avatar}>
      <img src={avatar} alt={displayName} />
    </div>
  );
};

const UserTag: FunctionComponent = () => {
  const { colorTheme } = useSidebarColorTheme();
  const styles = useCSSRulesWithTheme(getCSSRules, { colorTheme });
  const userRoles = useUserRolesFromAccount({});
  const { flagEnabled: debuggerEnabled } = useFeatureFlag(
    Flags.ClientRolesAndPermsDebugger
  );

  const {
    userStore: { user, displayName },
  } = useRootStore();

  if (!user) {
    return <></>;
  }

  const { profilePictureURL } = user;

  return (
    <div css={styles.accountMenu} data-testing-id={"account-menu"}>
      {debuggerEnabled ? (
        <Tooltip
          text={
            <>
              {userRoles.map((role) => (
                <div key={role}>{role}</div>
              ))}
            </>
          }
          zIndex={10000}
          size="md"
        >
          <div>
            <ProfileImage
              profilePictureURL={profilePictureURL}
              displayName={displayName}
            />
          </div>
        </Tooltip>
      ) : (
        <ProfileImage
          profilePictureURL={profilePictureURL}
          displayName={displayName}
        />
      )}
      <div css={styles.caption}>
        <Typography variant="textMedium">{displayName}</Typography>
      </div>
      <GradientStroke />
    </div>
  );
};

export default observer(UserTag);
