/** @jsxImportSource @emotion/react */

import {
  BasicExperienceObject,
  JobExperienceData,
} from "@a_team/models/dist/ExperienceObject";
import { Toggle, theme } from "@a_team/ui-components";
import { BuilderProfileShareModal } from "components/BuilderProfileShareModal";
import { BuilderProfileCard } from "components/DiscoveryCards";
import FeatureFlagComponent from "components/FeatureFlag";
import { Flags } from "configs/featureFlags";
import scrollToSection from "helpers/scrollToSection";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ExperienceByUserId } from "models/Experience";
import { useCurrentAccount } from "queries/accounts/useCurrentAccount";
import { useExperiencesByUserId } from "queries/experiences/useExperiencesByUserId";
import { useNarrativesSummaryForUserId } from "queries/narratives/useNarrativesForUserId";
import { useSimilarBuildersForUserId } from "queries/similarBuilders/useSimilarBuildersForUserId";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { FC, useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Redirect, useParams } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useRootStore } from "store";
import leftArrowIcon from "views/Builder/assets/left-arrow.svg";
import rightArrowIcon from "views/Builder/assets/right-arrow.svg";
import { About } from "views/Builder/components/About";
import { AdditionalRoles } from "views/Builder/components/AdditionalRoles";
import AiChatCta from "views/Builder/components/AiChatCta";
import { CollapsedProfile } from "views/Builder/components/CollapsedProfile";
import { Connections } from "views/Builder/components/Connections";
import { Divider } from "views/Builder/components/Divider";
import { Header } from "views/Builder/components/Header";
import { Industries } from "views/Builder/components/Industries";
import { MobileStarButton } from "views/Builder/components/MobileStarButton";
import { Profile } from "views/Builder/components/Profile";
import { Projects } from "views/Builder/components/Projects";
import { Recommendations } from "views/Builder/components/Recommendations";
import { Skills } from "views/Builder/components/Skills";
import { WorkHistory } from "views/Builder/components/WorkHistory";
import { Accomplishments } from "views/Builder/components/accomplishments";
import { MAX_PROFILE_WIDTH } from "views/Builder/components/constants";

interface BuilderProps {
  isAuthGated?: boolean;
}

const getCSSRules: CSSRulesResolver = () => ({
  content: {
    margin: "0 auto",
    maxWidth: MAX_PROFILE_WIDTH,
    paddingLeft: 15,
    paddingRight: 15,
  },
  similarBuildersContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  similarBuildersTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    fontSize: 18,
    fontWeight: 500,
    color: "#000000",
  },
  similarBuildersSubtitle: {
    fontSize: 14,
    color: "#000000",
  },
  similarBuildersInternalContainer: {
    marginTop: 16,
  },
  similarBuilderSlideContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  similarBuildersSlideButton: {
    width: 32,
    height: 32,
    cursor: "pointer",
    borderRadius: 8,
    boxShadow: "0px 1px 8px 0px #00000033",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const Builder: FC<BuilderProps> = ({ isAuthGated }) => {
  const {
    uiStore: { setApiErrorToast },
    userStore: { isAdmin },
  } = useRootStore();

  const [selectedJob, setSelectedJob] = useState<
    (BasicExperienceObject & JobExperienceData) | undefined
  >();

  const [selectedProject, setSelectedProject] =
    useState<ExperienceByUserId["projects"][0]>();

  const { id } = useParams<{ id: string }>();
  const { data: currentAccount } = useCurrentAccount();
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useUserProfileByUserId(id);
  const { data: experiences, isLoading: isLoadingExperiences } =
    useExperiencesByUserId(id);
  const { data: narrativesSummary } = useNarrativesSummaryForUserId(id);
  const { data: similarBuilders } = useSimilarBuildersForUserId(
    currentAccount?.clientCompany?.id || "",
    id
  );

  const isSmallScreen = useBelowBreakpoint("sm");
  const [showShareProfileModal, setShowShareProfileModal] = useState(false);
  const [showSimilarBuilders, setShowSimilarBuilders] = useState(false);
  const styles = useCSSRulesWithTheme(getCSSRules);
  const screenClass = useScreenClass();

  useEffect(() => {
    setShowSimilarBuilders(
      (userProfile?.talentProfile?.talentSpecializations
        ?.mainTalentSpecialization?.name &&
        (!userProfile.calComUserId || !userProfile.calComConnected) &&
        similarBuilders &&
        similarBuilders.length > 0) ||
        false
    );
  }, [userProfile, similarBuilders]);

  useEffect(() => {
    scrollToSection();
  }, [userProfile, showSimilarBuilders]);

  const { flagEnabled: showArchitects } = useFeatureFlag(Flags.ShowArchitects);
  const { flagEnabled: showBuilderRatings } = useFeatureFlag(
    Flags.BuilderRatings
  );

  const architectData = showArchitects
    ? userProfile?.advisorDetails
    : undefined;

  if (
    (!isLoadingUserProfile && !userProfile) ||
    (!isLoadingExperiences && !experiences)
  ) {
    setApiErrorToast(
      new Error("Unable to load user profile or experiences"),
      "Unable to load builder"
    );
    return <Redirect to="/" />;
  }

  const height = isSmallScreen ? 104 : 176;
  const padding = isSmallScreen ? 16 : 24;
  const context = { page: "profile" };

  return (
    <SkeletonTheme baseColor={theme.colors.Grey[200]}>
      <Header
        animate={true}
        height={height}
        isAuthGated={isAuthGated}
        minHeight={64}
        architectData={architectData}
      >
        {userProfile && (
          <CollapsedProfile
            user={userProfile}
            onShare={() => setShowShareProfileModal(true)}
          />
        )}
      </Header>
      <div css={styles.content}>
        <Profile
          animate={true}
          height={height}
          isAuthGated={isAuthGated}
          narrativeSummary={narrativesSummary}
          onShare={() => setShowShareProfileModal(true)}
          padding={padding}
          user={userProfile}
          minHeight={64}
          spacerHeight={isSmallScreen ? 120 : height}
          context={context}
          architectData={architectData}
          showPlatformProfileLink={isAdmin}
        />

        <FeatureFlagComponent name={Flags.AiChatBuilderCardCta} on>
          {userProfile && <AiChatCta profile={userProfile} />}
        </FeatureFlagComponent>
        <FeatureFlagComponent name={Flags.ClientProfileSimilarBuilders} on>
          {userProfile && (showSimilarBuilders || isAdmin) && (
            <div css={styles.similarBuildersContainer}>
              <div css={styles.similarBuildersTitle}>
                {isAdmin && (
                  <Toggle
                    checked={showSimilarBuilders}
                    onChange={setShowSimilarBuilders}
                  />
                )}
                {"Similar Builders"}
              </div>
              <div css={styles.similarBuildersInternalContainer}>
                {showSimilarBuilders && userProfile?.uid && (
                  <div css={styles.similarBuilderSlideContainer}>
                    <div css={styles.similarBuildersSubtitle}>
                      {`As ${userProfile?.fullName} is currently unavailable for booking an interview, we suggest exploring similar builders who are available right now.`}
                    </div>
                    <Slide
                      slidesToScroll={isSmallScreen ? 1 : 2}
                      slidesToShow={isSmallScreen ? 1 : 2}
                      indicators={true}
                      autoplay={false}
                      transitionDuration={300}
                      prevArrow={
                        <button
                          css={styles.similarBuildersSlideButton}
                          style={{
                            left: screenClass === "xs" ? 0 : -16,
                          }}
                        >
                          <img src={leftArrowIcon} />
                        </button>
                      }
                      nextArrow={
                        <button css={styles.similarBuildersSlideButton}>
                          <img src={rightArrowIcon} />
                        </button>
                      }
                    >
                      {similarBuilders?.map((builder, index) => (
                        <BuilderProfileCard
                          key={builder.uid}
                          builder={builder}
                          context={context}
                          showChatCta={false}
                          css={{
                            height: 306,
                            margin: index % 2 === 1 ? "0px 16px" : "0px",
                          }}
                          suggestedFromBuilderId={userProfile.uid}
                        />
                      ))}
                    </Slide>
                  </div>
                )}
              </div>
            </div>
          )}
        </FeatureFlagComponent>
        <About user={userProfile} />
        <Divider />
        <Accomplishments user={userProfile} />
        {!isAuthGated && (
          <>
            <WorkHistory
              setSelectedProject={(eid: string) => {
                setSelectedProject(
                  experiences?.projects?.find((p) => p.eid === eid)
                );
                setSelectedJob(undefined);
              }}
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
              jobs={experiences?.jobs}
              user={userProfile}
            />
            <Projects
              setSelectedJob={(eid: string) => {
                setSelectedJob(
                  experiences?.jobs?.find((job) => job.eid === eid)
                );
                setSelectedProject(undefined);
              }}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              projects={experiences?.projects}
              user={userProfile}
            />
            <Divider />
            {userProfile?.uid && showBuilderRatings && (
              <>
                <Recommendations builderId={userProfile?.uid} />
                <Divider />
              </>
            )}

            <Skills user={userProfile} />
            <Industries user={userProfile} />
            <AdditionalRoles user={userProfile} />
            <Divider />
            <Connections
              narrativesSummary={narrativesSummary}
              parentUser={userProfile}
            />
          </>
        )}
      </div>
      <MobileStarButton builder={userProfile} context={context} />
      <BuilderProfileShareModal
        isOpen={showShareProfileModal}
        onClose={() => setShowShareProfileModal(false)}
        builder={userProfile}
      />
    </SkeletonTheme>
  );
};
