import { theme } from "@a_team/ui-components/lib/theme";
import createCache from "@emotion/cache";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Routes from "Routes";
import analytics from "analytics";
import AnalyticsProvider from "analytics/Provider";
import { BillingInformationProvider } from "components/BillingInformation";
import ErrorBoundary from "components/ErrorBoundary";
import ForcingModalProvider from "components/ForcingModal";
import GlobalModalsProvider from "components/GlobalModals";
import NavStateProvider from "components/NavStateProvider";
import { PaymentMethodsProvider } from "components/PaymentMethods";
import { ProposalContextProvider } from "components/Proposals/ReviewBuilders/ProposalContext";
import ScreenCoverProvider from "components/ScreenCover";
import ToastProvider from "components/ToastProvider";
import config from "config";
import { ScreenClassProvider } from "react-grid-system";
import { HelmetProvider } from "react-helmet-async";
import { RootStoreProvider } from "store";
import { RegisterContextProvider } from "views/Register/Context";
import { InviteCollaboratorProvider } from "./components/AccountCollaboratorInviteModal/Provider";
import { AccessModalProvider } from "components/AccessModalProvider";

declare global {
  interface Window {
    Cypress?: any;
  }
}

function App() {
  // Important: Tracking must be enabled to work, but we don't want to track Cypress tests
  analytics.setEnabled(!window?.Cypress);
  analytics.init(!!process.env.REACT_APP_ANALYTICS_DEBUG);

  const emotionCache = createCache({
    ...(!config.isProd && { stylisPlugins: [] }),
    key: "em-",
  });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        useErrorBoundary: false,
        // Time until background re-fetch happens
        refetchOnWindowFocus: false,
        // See: https://tanstack.com/query/v4/docs/react/guides/important-defaults
        staleTime: 1000 * 60 * 5, // Default: 0
        // Time until query result cache is considered invalid
        cacheTime: 1000 * 60 * 5, // Default 5 mins
      },
      mutations: {
        useErrorBoundary: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ScreenClassProvider>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={theme}>
            <RootStoreProvider queryClient={queryClient}>
              <ErrorBoundary>
                <ToastProvider>
                  <HelmetProvider>
                    <AnalyticsProvider>
                      <ProposalContextProvider>
                        <AccessModalProvider>
                          <RegisterContextProvider>
                            <GlobalModalsProvider>
                              <ForcingModalProvider>
                                <ScreenCoverProvider>
                                  <NavStateProvider>
                                    <Routes />
                                  </NavStateProvider>
                                </ScreenCoverProvider>
                              </ForcingModalProvider>
                            </GlobalModalsProvider>
                          </RegisterContextProvider>
                        </AccessModalProvider>
                      </ProposalContextProvider>
                      <BillingInformationProvider />
                      <PaymentMethodsProvider />
                      <InviteCollaboratorProvider />
                    </AnalyticsProvider>
                  </HelmetProvider>
                </ToastProvider>
              </ErrorBoundary>
            </RootStoreProvider>
          </ThemeProvider>
        </CacheProvider>
      </ScreenClassProvider>
      {config.REACT_APP_SHOW_REACT_QUERY_DEV_TOOLS === "true" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
}

export default App;
