import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useProposal from "queries/proposals/useProposal";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const ENABLED_AFTER = new Date("2023-12-18");

/**
 * Custom hook to determine the availability of direct scheduling.
 *
 * This hook is part of a feature toggle pattern for the direct scheduling feature.
 * It is intended as a temporary measure to control access to the feature during a phased rollout.
 * Once the feature is fully deployed and stable, this hook and its associated toggles should be removed.
 * @TODO: https://buildateam.atlassian.net/browse/TC-2920
 *
 * The hook performs the following checks:
 * - Verifies if the feature flag for direct scheduling is enabled.
 * - Determines the proposal context based on the current route.
 * - Fetches the proposal data using the `useProposal` hook.
 * - Compares the proposal creation date against a predefined threshold to decide if the feature should be active.
 *
 * @returns {boolean} A boolean value indicating whether direct scheduling is enabled for the current proposal context.
 */
export const useIsDirectSchedulingEnabled = (): boolean => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { flagEnabled } = useFeatureFlag(Flags.DirectScheduling);
  const { data: proposal } = useProposal(proposalId);

  const isDirectSchedulingEnabled = useMemo(() => {
    if (!proposal) {
      return false;
    }
    const proposalCreatedAt = new Date(proposal.createdAt);

    return flagEnabled && proposalCreatedAt > ENABLED_AFTER;
  }, [flagEnabled]);

  return isDirectSchedulingEnabled;
};
