import {
  RegisteredUserObject,
  UserCardObject,
} from "@a_team/models/dist/UserObject";
import { logger } from "@sentry/utils";
import { ObjectID } from "bson";
import { format } from "date-fns";
import { chunk } from "lodash";

const DEFAULT_DATE_FORMAT = "PP";

export const generateUniqueId = (): string => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const generateObjectId = (): string => {
  return new ObjectID().toHexString();
};

export const formatDate = (
  date: Date | string,
  dateFormat = DEFAULT_DATE_FORMAT
): string => {
  try {
    const d = date instanceof Date ? date : new Date(date);
    return format(d, dateFormat);
  } catch (error) {
    console.error("[formatDate error]", error);
    return "";
  }
};

export const isValidUrl = (url: string): boolean => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z][a-z]+" + // validate domain name
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "ig" // flags
  );
  return !!urlPattern.test(url);
};

export const withHttps = (url: string): string =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

export const getUserDisplayName = (
  user:
    | RegisteredUserObject
    | UserCardObject
    | { firstName: string; lastName?: string },
  punctuation = "."
): string => {
  if (!user) {
    return "";
  }

  const { firstName, lastName } = user;

  if (!lastName) {
    return firstName;
  }

  const initial = lastName[0];

  return `${firstName} ${initial}${punctuation}`;
};

export const getFirstAndLastNameFromFullName = (
  fullName: string = ""
): { firstName: string; lastName: string } => {
  const split = fullName.trim().split(" ");
  return {
    firstName: split[0],
    lastName: split.slice(1).join(" "),
  };
};

export const capitalizeFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Truncates a given string according to a given char limit and adds '...'
 * @param {String} string - The string to truncate
 * @param {Number} limit - The chars limit to cut at string at
 */
export const truncate = (
  string: string,
  limit: number,
  suffix: string = "…"
): string =>
  string.length > limit ? `${string.substring(0, limit)}${suffix}` : string;

export const getUserInitials = (
  user: RegisteredUserObject | UserCardObject | undefined
): string => {
  if (!user) {
    return "";
  }

  return `${user?.firstName?.charAt(0) || ""}${
    user?.lastName?.charAt(0) || ""
  }`;
};

export function pluralizeString(
  count: number,
  singular: string,
  plural?: string
) {
  plural = plural || `${singular}s`;
  return `${count} ${count === 1 ? singular : plural}`;
}

export function matchesMissionRoute(route: string) {
  return route.match(/^\/mission\/(.*)/);
}

export function getMissoinIdFromRoute(route: string): string | undefined {
  const missionId = matchesMissionRoute(route) && route.split("/")[2];
  return missionId && missionId.length ? missionId : undefined;
}
export function matchExactCase(input: string, target: string): string {
  let result = "";

  for (let i = 0; i < Math.min(input.length, target.length); i++) {
    const charFromInput = input[i];
    const charFromTarget = target[i];

    if (charFromInput.toLowerCase() === charFromTarget.toLowerCase()) {
      result += charFromTarget;
    } else {
      result += charFromInput;
    }
  }

  // Append any remaining characters from the input string
  if (input.length > target.length) {
    result += input.slice(target.length);
  }

  return result;
}

export function commaAndJoin(arr: Array<string>) {
  const length = arr.length;

  if (length === 0) {
    return "";
  } else if (length === 1) {
    return arr[0];
  } else {
    const [allButLast, [last]] = chunk(arr, length - 1);

    return `${allButLast.join(", ")} and ${last}`;
  }
}

export const getLinkedInProfileUrl = (username: string) =>
  `https://linkedin.com/in/${username}`;

export const getGithubProfileUrl = (username: string) =>
  `https://github.com/${username}`;

export function stripHtml(html: string) {
  try {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  } catch (error) {
    logger.error(error);
    return html;
  }
}

export function addSuffixIfMissing(
  str: string,
  suffix: string,
  spaceBeforeSuffix?: boolean
) {
  return str.match(new RegExp(suffix + "$", "gi"))
    ? str
    : str + (spaceBeforeSuffix ? " " : "") + suffix;
}

const VOWELS = ["a", "e", "i", "o", "u"];
export function addIndefiniteArticle(str: string) {
  return VOWELS.includes(str[0].toLowerCase()) ? `an ${str}` : `a ${str}`;
}

export const formatYearsExperience = (years?: number | null) => {
  return years && years > 20 ? "20+" : (years || 0).toString();
};
