import { ColorAlias } from "@a_team/ui-components";
import {
  AdminBaseLocation,
  AuthCuratorLocation,
  ClientSettingsBaseLocation,
  DiscoverLocation,
  HomeLocation,
  InterviewScheduleLocation,
  LegacyDiscoverLocation,
  NewMissionLocation,
  SetupGuideLocation,
  ShortlistLocation,
  ToMissionLocation,
  TokensLocation,
} from "Locations";
import Navigation from "components/Sidebar/Navigation";
import {
  NavigationItem,
  NavigationItemProps,
} from "components/Sidebar/Navigation/NavigationItem";
import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useShowSetupGuide } from "hooks/useShowSetupGuide";
import { observer } from "mobx-react";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { useLatestMissionTitles } from "queries/missionSpecs/useLatestMissionTitles";
import { useClientInterviews } from "queries/scheduling/useClientInterviews";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import plusIcon from "./assets/plus.svg";

const MainNavigation = () => {
  const {
    missionSpecStore,
    uiStore: {
      sidebarOpen,
      setAdminLastVisited,
      isSearchOpen,
      setIsSearchOpen,
      setIsCollaboratorInviteModalOpen,
    },
    userStore: { isAdmin },
  } = useRootStore();

  const { push } = useHistory();
  const location = useLocation();

  const { data, isLoading: shortlistBuildersLoading } = useStarredBuilders({});
  const countStarredUsers = useMemo(() => {
    if (shortlistBuildersLoading) {
      return 0;
    }
    return data?.size || 0;
  }, [data?.size, shortlistBuildersLoading]);
  const { data: missionTitles = [], dataUpdatedAt: missionTitlesUpdatedAt } =
    useLatestMissionTitles();
  const showSetupGuide = useShowSetupGuide();
  const { flagEnabled: showUniversalSearch } = useFeatureFlag(
    Flags.UniversalSearch
  );
  const { flagEnabled: showInterviews } = useFeatureFlag(
    Flags.InterviewSchedule
  );
  const { flagEnabled: withZeroFrictionSignUp } = useFeatureFlag(
    Flags.ZeroFrictionSignUp
  );
  const { flagEnabled: showCurator } = useFeatureFlag(Flags.CuratorMainNav);

  const { data: clientInterviews } = useClientInterviews({});

  const colorAliases: ColorAlias[] = [
    "Baracus@500",
    "Faceman@600",
    "Green@600",
    "Hannibal@600",
    "Murdock@700",
    "Red@500",
  ];

  const items = useMemo(() => {
    const children = missionTitles
      .filter(({ mid }) => !!mid)
      .map(
        ({ title, mid }, i): NavigationItemProps => ({
          color: colorAliases[Math.floor(Math.random() * colorAliases.length)],
          iconName: "teamSign",
          iconSize: "md",
          id: mid || i.toString(),
          label: title || "",
          path: ToMissionLocation(mid!),
          enabled: true,
        })
      );

    const items: NavigationItemProps[] = [
      {
        id: "admin",
        label: "Admin Dashboard",
        iconName: "lock",
        handleClick: () => {
          setAdminLastVisited(location.pathname);
          push(AdminBaseLocation);
        },
        enabled: isAdmin,
      },
      {
        id: "search",
        label: "Search",
        iconName: "search",
        handleClick: () => {
          setIsSearchOpen(!isSearchOpen);
        },
        enabled: showUniversalSearch,
      },
      {
        id: "curator",
        label: "Team Formation AI",
        iconName: "aiStar",
        path: AuthCuratorLocation,
        enabled: showCurator,
        newBadge: true,
      },
      {
        id: "separator",
        label: "separator",
        divider: true,
        enabled: isAdmin,
      },
      {
        id: "guide",
        label: "Get started guide",
        path: SetupGuideLocation,
        iconName: "getStartedGuide",
        enabled: showSetupGuide,
      },
      {
        id: "legacy-discovery",
        label: "Discovery",
        path: LegacyDiscoverLocation,
        iconName: "previewOn",
        enabled: false,
      },
      {
        id: "discover",
        label: "Discover",
        path: DiscoverLocation,
        iconName: "discovery",
        enabled: true,
      },
      {
        id: "shortlist",
        label: "Shortlisted builders",
        iconName: "star",
        path: ShortlistLocation,
        enabled: countStarredUsers > 0,
        badge: String(countStarredUsers),
      },
      {
        action: () => {
          missionSpecStore.unsetMission();
          push(NewMissionLocation);
        },
        actionIcon: plusIcon,
        children: sidebarOpen ? children : undefined,
        enabled: true,
        iconName: "projects",
        id: "my-teams",
        initOpened: false,
        label: "My teams",
        path: HomeLocation,
        testid: "back-to-dashboard-button",
      },
      {
        handleClick: () => {
          setIsCollaboratorInviteModalOpen(true);
        },
        id: "invite",
        label: "Invite collaborators",
        iconName: "key",
        enabled: withZeroFrictionSignUp,
      },
      {
        id: "interviews",
        label: "Interviews",
        path: InterviewScheduleLocation,
        iconName: "comment",
        enabled: showInterviews,
        badge: clientInterviews ? String(clientInterviews.length) : "",
      },
      {
        id: "tokens",
        label: "Tokens",
        path: TokensLocation,
        iconName: "tokens",
        enabled: !showUniversalSearch,
      },
      {
        id: "settings",
        label: "Settings",
        path: ClientSettingsBaseLocation,
        iconName: "settings",
        enabled: true,
      },
    ];

    return items;
  }, [
    missionTitlesUpdatedAt,
    sidebarOpen,
    isSearchOpen,
    showSetupGuide,
    countStarredUsers,
    isAdmin,
    showUniversalSearch,
    showCurator,
  ]);

  const enabledItems = items.filter((item) => item.enabled && !item.divider);

  return (
    <Navigation>
      {items.map((item) => (
        <NavigationItem
          {...item}
          totalCount={enabledItems.length}
          key={`navigation--main--${item.id}`}
        />
      ))}
    </Navigation>
  );
};

export default observer(MainNavigation);
