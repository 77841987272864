import { Modal, Typography } from "@a_team/ui-components";
import { useAccount } from "queries/accounts/useAccount";
import { useRootStore } from "store";
import { AccountCollaboratorInviteForm } from "components/Proposals/AdminApproveAndShareReviewModal/AccountCollaboratorInviteForm";
import MissionSpec from "models/MissionSpec";
import { addSuffixIfMissing } from "helpers/strings";

export const AccountCollaboratorInviteModal = ({
  isOpen,
  onClose,
  missionSpec,
}: {
  isOpen: boolean;
  onClose: () => void;
  missionSpec?: MissionSpec;
}) => {
  const {
    accountsStore: { currentAccountId },
  } = useRootStore();

  const { data: account } = useAccount(currentAccountId);

  if (!account) {
    return <></>;
  }

  const workspaceName = account.workspace?.name || "";
  const missionName = missionSpec?.title || "";

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      hideCloseButton={false}
      onClose={onClose}
      zIndex={10001}
    >
      <Typography variant="textLarge" component={"div"} weight="bold">
        {missionSpec
          ? addSuffixIfMissing(
              `Invite collaborators to ${missionName}`,
              "mission",
              true
            )
          : addSuffixIfMissing(
              `Invite collaborators to ${workspaceName}`,
              "workspace",
              true
            )}
      </Typography>
      <Typography variant="textMedium" component={"p"}>
        {missionSpec
          ? "You can invite people you work with to collaborate on your mission."
          : "You can invite people you work with to collaborate in your workspace."}
      </Typography>
      <AccountCollaboratorInviteForm callback={onClose} />
    </Modal>
  );
};
