/** @jsxImportSource @emotion/react */

import { Spinner } from "@a_team/ui-components";
import { DiscoverLocationV2, HomeLocation, ToMissionLocation } from "Locations";
import tracking from "analytics";
import { VIEWED_NATIVE_PROPOSAL } from "analytics/events";
import ErrorBoundary from "components/ErrorBoundary";
import AdminApproveAndShareReviewModal from "components/Proposals/AdminApproveAndShareReviewModal";
import AdminReviewRejectModal from "components/Proposals/AdminRejectReviewModal";
import ReviewBuilders, {
  BuildersReviewStage,
} from "components/Proposals/ReviewBuilders";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import { observer } from "mobx-react-lite";
import { AdminRejectionReason, AdminReviewStatus } from "models/Proposal";
import useProposal from "queries/proposals/useProposal";
import { useSubmitAdminReview } from "queries/proposals/useSubmitAdminReview";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRootStore } from "store";
import { FlexCenter } from "views/Builder/components/Flex";
import NativeProposal from "views/Mission/Proposals/Native/Index";
import NoAccessView from "views/NoAccess";

export const shareModalOpenSources = {
  approve: "APPROVE BUTTON",
  share: "SHARE BUTTON",
};

const PublicProposalView: FC = () => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const history = useHistory();
  const {
    uiStore: { setToast },
  } = useRootStore();
  const [reviewBuildersStage, setReviewBuildersStage] = useState<
    BuildersReviewStage | undefined
  >(undefined);
  const { data: proposal, isLoading, error } = useProposal(proposalId);
  const { setCurrentProposal } = useProposalContext();

  const { mutate: submitAdminReview } = useSubmitAdminReview(() => {
    const redirect = proposal?.missionSpecId
      ? ToMissionLocation(proposal.missionSpecId)
      : HomeLocation;

    history.replace(ToMissionLocation(redirect));
    setToast({
      text: "Proposal rejected and hidden",
      type: "success",
    });
  });

  const [reviewBuildersModalOpen, setReviewBuildersModalOpen] = useState(false);
  const [adminRejectProposalOpen, setAdminRejectProposalOpen] = useState(false);
  const [shareProposalModalOpen, setShareProposalModalOpen] = useState(false);
  const [shareModalOpenSource, setShareModalOpenSource] = useState(
    shareModalOpenSources.approve
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!proposal) {
      if (
        error &&
        (error as unknown as any).message !== "Something went wrong" // TODO: Handle this error properly with no access error
      ) {
        setToast({
          text: "Proposal not found",
          type: "error",
          duration: 3000,
        });

        // Redirect back home
        history.push(HomeLocation);
      }
      return;
    }

    setCurrentProposal(proposal!);

    // Track view of native proposal
    tracking.track(VIEWED_NATIVE_PROPOSAL, {
      proposalId: proposal?.id,
      proposalVersion: proposal?.version,
      proposalSharedPublicly: !!proposal?.publicUntil,
    });
  }, [proposal, isLoading]);

  const onAdminReviewProposalClick = (isApproval: boolean) => {
    if (isApproval) {
      setShareModalOpenSource(shareModalOpenSources.approve);
      setShareProposalModalOpen(true);
    } else {
      setAdminRejectProposalOpen(true);
    }
  };

  const onAdminConfirmRejectClick = (
    rejectionReason?: AdminRejectionReason | string
  ) => {
    const adminReview = {
      status: AdminReviewStatus.Rejected,
      rejectionReason: rejectionReason as AdminRejectionReason,
      source: shareModalOpenSource,
    };

    submitAdminReview({
      proposalId: proposalId,
      adminReview,
      missionId: proposal?.missionId as string,
    });

    setAdminRejectProposalOpen(false);
  };

  const onAdminShareProposalClick = () => {
    setShareModalOpenSource(shareModalOpenSources.share);
    setShareProposalModalOpen(true);
  };

  const onReviewProposalClick = (stage?: BuildersReviewStage) => {
    if (stage && typeof stage === "string") {
      setReviewBuildersStage(stage);
    }
    setReviewBuildersModalOpen(true);
  };

  const onReviewBuildersModalClose = async () => {
    setReviewBuildersModalOpen(false);
  };

  const handleBuildersReviewSubmit = () => {
    setToast({
      text: "Team feedback submitted",
      type: "success",
    });
    onReviewBuildersModalClose();
  };

  if (isLoading) {
    return (
      <FlexCenter
        style={{
          height: "100vh",
        }}
      >
        <Spinner />
      </FlexCenter>
    );
  }

  if (!proposal || proposal.version !== 2) {
    return (
      <NoAccessView
        title="You don't have access to this proposal"
        description="Sign in or create an account to see if you have access to this proposal."
      />
    );
  }

  return (
    <>
      <NativeProposal
        proposal={proposal!}
        onAdminReviewProposalClick={onAdminReviewProposalClick}
        onAdminShareProposalClick={onAdminShareProposalClick}
        onReviewProposalClick={onReviewProposalClick}
      />
      {reviewBuildersModalOpen && (
        <ReviewBuilders
          isOpen={reviewBuildersModalOpen}
          onClose={onReviewBuildersModalClose}
          onSubmit={handleBuildersReviewSubmit}
          overrideStage={reviewBuildersStage}
        />
      )}
      {adminRejectProposalOpen && (
        <AdminReviewRejectModal
          isOpen={adminRejectProposalOpen}
          onClose={() => setAdminRejectProposalOpen(false)}
          onSubmit={onAdminConfirmRejectClick}
        />
      )}
      {shareProposalModalOpen && (
        <AdminApproveAndShareReviewModal
          proposalId={proposalId}
          missionId={proposal?.missionId}
          missionSpecId={proposal?.missionSpecId}
          reviewStatus={proposal?.adminReview?.status as AdminReviewStatus}
          isOpen={shareProposalModalOpen}
          onClose={() => setShareProposalModalOpen(false)}
          source={shareModalOpenSource}
        />
      )}
    </>
  );
};

const ProposalView = observer(PublicProposalView);

const ProposalViewWithErrorBoundary = () => (
  <ErrorBoundary links={[["Discover builders", DiscoverLocationV2]]}>
    <ProposalView />
  </ErrorBoundary>
);

export default ProposalViewWithErrorBoundary;
