import { useQuery } from "@tanstack/react-query";
import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import {
  InterviewScheduleFilters,
  getClientInterviews,
} from "services/scheduling";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useClientInterviews = (filters: InterviewScheduleFilters) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    filters: JSON.stringify(filters),
  });

  const { isLoggedIn, accountToken } = authStore;
  const { flagEnabled } = useFeatureFlag(Flags.InterviewSchedule);

  const { queryKey } = queryKeys.scheduling.clientInterviews(specificity);

  return useQuery({
    queryKey,
    queryFn: async () => getClientInterviews(authStore, filters),
    enabled: isLoggedIn && !!accountToken && flagEnabled,
    onError: (error) => setApiErrorToast(error, "Failed to fetch interviews"),
  });
};
