/** @jsxImportSource @emotion/react */

import { Avatar, Button, Typography, theme } from "@a_team/ui-components";
import { ToBuilderLocation } from "Locations";
import BookInterviewButtonWrapper from "components/BookInterviewButton/BookInterviewButtonWrapper";
import InterviewStatusList from "components/InterviewStatus/List";
import TrackButton from "components/TrackButton";
import {
  getGithubProfileUrl,
  getLinkedInProfileUrl,
  truncate,
} from "helpers/strings";
import useCanViewProposalRates from "hooks/access/useCanViewProposalRates";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useIsDirectSchedulingEnabled } from "hooks/useIsDirectSchedulingEnabled";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { observer } from "mobx-react";
import { ClientSignalActionType } from "models/ClientSignal";
import {
  ClientReviewStatus,
  ProposalObject,
  ProposalRole,
  ProposalRoleBuilder,
} from "models/Proposal";
import { useClientSignalsForBuilder } from "queries/discover/useClientSignalsForBuilder";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import { Flex } from "views/Builder/components/Flex";
import { Markdown } from "views/Builder/components/Markdown";
import { Jobs } from "views/Mission/Proposals/Native/Jobs";
import {
  MetaData,
  MetaDataComponent,
} from "views/Mission/Proposals/Native/MetaDataComponent";
import { Pill } from "views/Mission/Proposals/Native/Pill";
import { Projects } from "views/Mission/Proposals/Native/Projects";
import { RatesComponent } from "views/Mission/Proposals/Native/RatesComponent";
import { Skills } from "views/Mission/Proposals/Native/Skills";
import { TruncatedContent } from "../../../Builder/components/TruncatedContent";
import { reformat } from "../../../Builder/components/utils";
import star from "./assets/star.svg";
import { ExperienceByUserId } from "models/Experience";
import {
  BasicExperienceObject,
  JobExperienceData,
} from "@a_team/models/dist/ExperienceObject";
import { Recommendations } from "./Recommendations";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag";
import { Flags } from "../../../../configs/featureFlags";

const BuilderSection: FC<
  PropsWithChildren<{ title: string; badge?: string }>
> = ({ title, badge, children }) => {
  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      paddingBottom: 24,
    },
    title: {
      paddingBottom: 4,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 8,
      },
    },
    badges: {
      paddingBottom: 4,
      display: "inline-block",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 8,
      },
    },
  }));

  return (
    <div css={styles.container}>
      {badge && (
        <div css={styles.badges}>
          <Pill>{badge}</Pill>
        </div>
      )}
      <div css={styles.title}>
        <Typography variant="textLarge" component="div">
          {title}
        </Typography>
      </div>
      <Typography variant="textMedium" component="div">
        {children}
      </Typography>
    </div>
  );
};

const MAX_EXPERIENCES = 3;

const FullBuilder = ({
  builder,
  role,
  proposal,
  onSelectForInterview,
  enableInteractions = false,
  isSampleProposal,
  isLoggedOut,
}: {
  builder: ProposalRoleBuilder;
  proposal: ProposalObject;
  role: ProposalRole;
  onSelectForInterview: (builderId: string) => void;
  enableInteractions?: boolean;
  isSampleProposal?: boolean;
  isLoggedOut?: boolean;
}) => {
  const { data: clientSignals } = useClientSignalsForBuilder(builder.uid);
  const showRates = useCanViewProposalRates();
  const directSchedulingEnabled = useIsDirectSchedulingEnabled();
  const showRateInSampleProposal = !!builder.clientDisplayRate;
  const { flagEnabled: withBuilderRatings } = useFeatureFlag(
    Flags.BuilderRatings
  );

  const [selectedProject, setSelectedProject] =
    useState<ExperienceByUserId["projects"][0]>();

  const [selectedJob, setSelectedJob] = useState<
    (BasicExperienceObject & JobExperienceData) | undefined
  >();

  const isSelectedForInterview =
    (builder.clientReview || {}).status ===
    ClientReviewStatus.NotifiedForInterview;

  const metaDataForBuilder = useMemo(() => {
    let metaData: MetaData = [];

    if (builder.uid) {
      metaData.push(["Profile", { link: ToBuilderLocation(builder.uid) }]);
    }

    if (builder.linkedin?.username) {
      metaData.push([
        "LinkedIn",
        {
          link: getLinkedInProfileUrl(builder.linkedin.username),
        },
      ]);
    }

    if (builder.github?.username) {
      metaData.push([
        "Github",
        {
          link: getGithubProfileUrl(builder.github.username),
        },
      ]);
    }

    if (builder.tfsPitch?.website) {
      metaData.push(["Website", { link: builder.tfsPitch?.website }]);
    }

    if (builder.websites && builder.websites.length > 0) {
      metaData.push(["Website", { link: builder.websites[0] }]);
    }

    if (builder?.cvURL) {
      metaData.push(["Resume", { link: builder.cvURL }]);
    }

    return metaData;
  }, [builder]);

  const starred = useMemo(() => {
    if (!clientSignals) {
      return false;
    }

    return clientSignals.some(
      ({ actionType }) => actionType === ClientSignalActionType.Star
    );
  }, [clientSignals]);

  const backgroundColor = useMemo(() => {
    if (starred) {
      return theme.colors.Baracus[100];
    }

    return theme.colors.Grey[0];
  }, [isSelectedForInterview, starred]);

  const truncateLimit = 500;
  const truncatedAboutMe =
    builder.aboutMe && builder.aboutMe.length > truncateLimit ? (
      <Markdown>{truncate(builder.aboutMe ?? "", truncateLimit)}</Markdown>
    ) : undefined;

  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    container: {
      display: "block",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    builder: {
      width: "auto",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 362,
      },
    },
    avatar: {
      marginBottom: 12,
      cursor: "pointer",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        marginBottom: 16,
      },
    },
    avatarImage: {
      width: 64,
      height: 64,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 124,
        height: 124,
      },
    },
    title: {
      fontSize: 24,
    },
    skills: {
      paddingTop: 4,
      paddingBottom: 24,
    },
    interviews: {
      marginTop: 0,
      marginBottom: 24,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        marginTop: 12,
        marginBottom: 12,
        width: 368,
      },
    },
    info: {
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 560,
        maxWidth: 560,
      },
      backgroundColor,
    },
    infoStarred: {
      borderRadius: 16,
      overflow: "hidden",
    },
    starred: {
      padding: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        padding: 24,
      },
    },
    starredHeader: {
      backgroundColor: "rgba(255, 233, 212, 0.50)",
      paddingBottom: 10,
      paddingLeft: 16,
      paddingRight: 12,
      paddingTop: 10,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 10,
        paddingLeft: 24,
        paddingRight: 12,
        paddingTop: 10,
      },
    },
    sm: {
      display: "none",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "block",
        flex1: {
          flex: 1,
        },
      },
      hide: {
        display: "block",
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          display: "none",
        },
      },
    },
    mt: {
      paddingTop: 24,
    },
    link: {
      textDecoration: "none",
      color: colors.Hannibal[600],
    },
    bookInterview: {
      marginTop: 20,
      width: 170,
    },
  }));

  const gotoProfile = () => {
    window.open(ToBuilderLocation(builder.uid), "_blank");
  };

  const filteredJobs = useMemo(() => {
    return builder.experiences?.jobs?.slice(0, 3).map((job) => ({
      ...job,
      relatedProjects: job?.relatedProjects?.filter((rp) =>
        builder?.experiences?.projects
          .slice(0, MAX_EXPERIENCES)
          .some((project) => project?.eid === rp.eid)
      ),
    }));
  }, [builder.experiences]);

  const filteredProjects = useMemo(() => {
    return builder.experiences?.projects?.slice(0, 3).map((project) => ({
      ...project,
      relatedJob: builder.experiences?.jobs
        .slice(0, MAX_EXPERIENCES)
        .find((job) => job?.eid === project?.relatedJob?.eid),
    }));
  }, [builder.experiences]);

  return (
    <div data-testing-id={"proposal-full-builder-card"}>
      <div css={styles.container}>
        <div css={styles.builder}>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <div css={styles.avatar}>
              <Avatar
                size="xxl"
                src={reformat(builder.profilePictureURL, "-/preview/300x300/")}
                fill={true}
                css={styles.avatarImage}
                onClick={gotoProfile}
              />
            </div>
            {enableInteractions && !proposal.isSampleProposal && (
              <div css={[styles.sm.hide]}>
                <InterviewButton
                  onClick={() => onSelectForInterview(builder.uid)}
                  selectedForInterview={isSelectedForInterview}
                  selectedText={"Selected"}
                  text={
                    directSchedulingEnabled ? "Book an interview" : "Select"
                  }
                />
              </div>
            )}
            {(proposal.isSampleProposal || isLoggedOut) && (
              <div css={styles.sm.hide}>
                <BookInterviewButtonWrapper userId={builder.uid} />
              </div>
            )}
          </Flex>
          <Typography variant="textMedium" component="div">
            {role.roleCategory?.title ? `${role.roleCategory.title} from ` : ""}
            {`${builder.location?.city}, ${
              builder.location?.country === "USA" && builder.location.province
                ? builder.location.province
                : builder.location?.country
            }`}
          </Typography>
          <Typography variant="textExtraLarge" weight="bold" css={styles.title}>
            {builder.fullName}
          </Typography>
          <div>
            <MetaDataComponent
              metaData={metaDataForBuilder}
              source={"Builder Full"}
              proposalId={proposal.id}
              isSampleProposal={!!isSampleProposal}
            />
          </div>
          {(proposal.isSampleProposal || isLoggedOut) && (
            <div css={styles.sm}>
              <div css={styles.bookInterview}>
                <BookInterviewButtonWrapper userId={builder.uid} />
              </div>
            </div>
          )}
          {(isSampleProposal ? showRateInSampleProposal : showRates) && (
            <RatesComponent
              currency={proposal.currency}
              hourlyRate={
                isSampleProposal || builder.showHourlyRate
                  ? builder.clientDisplayRate
                  : undefined
              }
              monthlyRate={
                builder.showMonthlyRate
                  ? builder.clientMonthlyDisplayRate
                  : undefined
              }
            />
          )}
          {builder.skillsOverlap && (
            <div css={styles.skills}>
              <Flex>
                <Skills skills={builder.skillsOverlap} />
              </Flex>
            </div>
          )}
          {enableInteractions && !proposal.isSampleProposal && (
            <div css={styles.sm}>
              <InterviewButton
                onClick={() => onSelectForInterview(builder.uid)}
                selectedForInterview={isSelectedForInterview}
                text={
                  directSchedulingEnabled
                    ? "Book an interview"
                    : "Select for interview"
                }
              />
            </div>
          )}
          {directSchedulingEnabled && (
            <div css={styles.interviews}>
              <InterviewStatusList
                proposalId={proposal.id}
                builderId={builder.uid}
              />
            </div>
          )}
        </div>
        <div css={[styles.info, starred && styles.infoStarred]}>
          {starred && (
            <div css={styles.starredHeader}>
              <Flex gap={4} justifyContent="flex-start">
                <ReactSVG src={star} />
                <Typography
                  variant="textMedium"
                  component="div"
                  style={{
                    paddingLeft: 8,
                  }}
                >
                  You shortlisted this profile previously
                </Typography>
              </Flex>
            </div>
          )}
          <div css={starred && styles.starred}>
            {!proposal.isSampleProposal && builder.pitch && (
              <BuilderSection title="How are your skills and experience relevant to this mission?">
                <Markdown>{builder.pitch}</Markdown>
              </BuilderSection>
            )}
            {(builder.customQuestionsReplies ?? []).map((reply, index) => {
              const question = (role.customQuestions ?? []).find(
                (q) => q.qid === reply.qid
              );

              const truncatedReply =
                reply && reply.text.length > truncateLimit ? (
                  <Markdown>
                    {truncate(reply.text ?? "", truncateLimit)}
                  </Markdown>
                ) : undefined;

              return (
                <BuilderSection
                  key={`${builder.uid}--custom-question--${index}`}
                  title={question?.text ?? ""}
                  badge="Your question"
                >
                  <TruncatedContent
                    expandComponent={(expand) => (
                      <Typography
                        color={"Hannibal@600"}
                        component={"div"}
                        onClick={() => expand()}
                        style={{ cursor: "pointer" }}
                        variant={"textMedium"}
                      >
                        {` Read more`}
                      </Typography>
                    )}
                    truncateComponent={(collapse) => (
                      <Typography
                        color={"Hannibal@600"}
                        component="div"
                        onClick={() => collapse()}
                        style={{ cursor: "pointer" }}
                        variant={"textMedium"}
                      >
                        {` Close`}
                      </Typography>
                    )}
                    truncatedContent={truncatedReply}
                  >
                    <Markdown>{reply.text}</Markdown>
                  </TruncatedContent>
                </BuilderSection>
              );
            })}
            {builder.aboutMe && (
              <BuilderSection title="About">
                <TruncatedContent
                  expandComponent={(expand) => (
                    <Typography
                      color={"Hannibal@600"}
                      component={"div"}
                      onClick={() => expand()}
                      style={{ cursor: "pointer" }}
                      variant={"textMedium"}
                    >
                      {` Read more`}
                    </Typography>
                  )}
                  truncateComponent={(collapse) => (
                    <Typography
                      color={"Hannibal@600"}
                      component="div"
                      onClick={() => collapse()}
                      style={{ cursor: "pointer" }}
                      variant={"textMedium"}
                    >
                      {` Close`}
                    </Typography>
                  )}
                  truncatedContent={truncatedAboutMe}
                >
                  <Markdown>{builder.aboutMe}</Markdown>
                </TruncatedContent>
              </BuilderSection>
            )}
            {filteredJobs && filteredJobs.length > 0 && (
              <BuilderSection title="Work history">
                <Jobs
                  setSelectedProject={(eid) => {
                    setSelectedProject(
                      filteredProjects?.find((project) => project.eid === eid)
                    );
                    setSelectedJob(undefined);
                  }}
                  selectedJob={selectedJob}
                  setSelectedJob={setSelectedJob}
                  jobs={filteredJobs}
                />
              </BuilderSection>
            )}
          </div>
          {builder.reviews && builder.reviews.length > 0 && withBuilderRatings && (
            <BuilderSection title="Recommendations from past A.Team missions">
              <div style={{ marginTop: 8 }}>
                <Recommendations reviews={builder.reviews} />
              </div>
            </BuilderSection>
          )}
        </div>
      </div>
      {filteredProjects && (
        <Projects
          setSelectedJob={(eid) => {
            setSelectedJob(filteredJobs?.find((job) => job.eid === eid));
            setSelectedProject(undefined);
          }}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          builder={builder}
          projects={filteredProjects}
        />
      )}
      {enableInteractions && !proposal.isSampleProposal && (
        <div css={[styles.sm.hide, styles.mt]}>
          <InterviewButton
            onClick={() => onSelectForInterview(builder.uid)}
            selectedForInterview={isSelectedForInterview}
            text={
              directSchedulingEnabled
                ? "Book an interview"
                : proposal.isSampleProposal
                ? "Request interview"
                : "Select for interview"
            }
          />
        </div>
      )}
    </div>
  );
};

const InterviewButton = ({
  onClick,
  selectedForInterview = false,
  selectedText = "Selected for interview",
  text = "Select for interview",
}: {
  onClick: () => void;
  selectedForInterview: boolean;
  selectedText?: string;
  text?: string;
}) => {
  const isDesktop = useMinBreakpoint("sm");
  const directSchedulingEnabled = useIsDirectSchedulingEnabled();

  if (selectedForInterview && !directSchedulingEnabled) {
    return (
      <Button
        iconProps={{ name: "statusPositiveNoBorder" }}
        variant="positive"
        size="md"
        style={{
          cursor: "default",
          width: isDesktop ? "auto" : "100%",
        }}
      >
        {selectedText}
      </Button>
    );
  }

  return (
    <TrackButton
      name={text}
      size="md"
      onClick={onClick}
      iconProps={
        selectedForInterview ? { name: "statusPositiveNoBorder" } : undefined
      }
      style={{ width: isDesktop ? "auto" : "100%" }}
    >
      {text}
    </TrackButton>
  );
};

export default observer(FullBuilder);
