/** @jsxImportSource @emotion/react */

import { css, CSSObject } from "@emotion/react";
import { Editor, EditorContent } from "@tiptap/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

interface TextEditorProps {
  editor: Editor;
  charCount?: number;
  readOnly?: boolean;
  maxCharCount?: string;
}

const TextEditor = ({
  charCount,
  editor,
  readOnly,
  maxCharCount,
}: TextEditorProps) => {
  const styles = useCSSRulesWithTheme(({ breakpoints, colors }) => ({
    root: {
      "& h3:first-of-type": {
        marginTop: 0,
      },
      "& h3": {
        color: colors.Grey["800"],
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "22px",
      },
      "& p": {
        color: colors.Grey["800"],
        fontSize: 15,
        fontWeight: 300,
        lineHeight: "20px",
      },
    },
    textEditorWrapper: {
      marginTop: 8,
      border: "1px solid #DADADC",
      borderRadius: 8,
      padding: 16,

      position: "relative",
      "& .ProseMirror": {
        padding: 0,
        border: "none",
        marginBottom: 40,
      },
      "& .ProseMirror-focused": {
        outline: "none",
      },
      "& .ProseMirror h3.is-empty:nth-child(1)::before": {
        content: "attr(data-placeholder)",
        float: "left",
        height: 0,
        pointerEvents: "none",
      },
      "& .ProseMirror p.is-empty:nth-child(2):last-child::before": {
        color: colors.Grey["400"],
        content: "attr(data-placeholder)",
        float: "left",
        height: 0,
        pointerEvents: "none",
      },
    } as CSSObject,
    textEditorWrapperReadOnly: {
      maxWidth: 750,
      "& h3": {
        marginTop: 40,
        marginBottom: 16,
        fontSize: 24,
        fontWeight: 500,
        lineHeight: "150%",
      },
      "& p": {
        marginTop: 0,
        fontSize: 16,
        marginBottom: 0,
        fontWeight: 400,
        lineHeight: "170%",
      },
      "& ul, ol": {
        paddingLeft: 15,
      },
    },
    charWrapper: {
      position: "absolute",
      bottom: 0,
      right: 16,
      fontSize: 12,
      lineHeight: "16px",
      paddingLeft: 32,
      paddingBottom: 16,
      width: "100%",
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
      },
    } as CSSObject,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      textEditorWrapper: {
        "& .ProseMirror": {
          marginBottom: 35,
        },
      },
    },
  }));

  return (
    <section
      css={css(
        styles.root,
        readOnly ? styles.textEditorWrapperReadOnly : styles.textEditorWrapper
      )}
    >
      <EditorContent editor={editor} />
      {!readOnly && (
        <div css={styles.charWrapper}>
          <div>
            <div>100 characters minimum</div>
            <div>
              {charCount}/{maxCharCount || "1,000"} characters
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TextEditor;
