import ApiToken from "@a_team/models/dist/ApiToken";
import { CompanyId } from "@a_team/models/dist/Company";
import {
  CurrentUserObject,
  RegisteredUserObject,
} from "@a_team/models/dist/UserObject";
import { AuthHydration } from "store/Auth";
import {
  EmployeeRange,
  FundingRange,
  RevenueRange,
} from "views/Register/utils";
import { UserStatus, UserType } from "./User";

export type BearerToken = string;

export interface ServiceAuth {
  bearerToken: BearerToken;
  invalidate(): unknown;
}

export interface RegisterCredentials {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface RegisterCompany {
  name: string;
  website: string;
  employeeRange: EmployeeRange;
  fundingRange: FundingRange;
  revenueRange: RevenueRange;
}

export interface RegisterEnrichmentResponse {
  companyId: CompanyId;
  domainIdentified: boolean;
  companyQualified: boolean;
  titleIdentified: boolean;
  companyName: string;
}

export enum RegisterErrorType {
  NON_WORK_EMAIL = "NON_WORK_EMAIL",
  EMAIL_ALREADY_REGISTERED = "EMAIL_ALREADY_REGISTERED",
}

export enum RegisterStage {
  CredentialsCollection = "CredentialsCollection",
  EmailVerification = "EmailVerification",
  RequestType = "RequestType",
  RequestSolution = "RequestSolution",
  RequestRoles = "RequestRoles",
  CompanyCollection = "CompanyCollection",
  RequestHelp = "RequestHelp",
}

export type AuthCredentials = {
  email?: string;
  password?: string;
};

export type AuthObject = {
  expiresIn?: number;
  companyId?: CompanyId;
  domainBlocked?: boolean;
  token: string;
  user: ApiTokenPayloadData | RegisteredUserObject;
  accountToken?: string;
  refreshToken?: string;
};

export interface UserAuthData {
  tokenData: ApiTokenPayloadData;
  user: CurrentUserObject | RegisteredUserObject;
}

export interface RenewTokenResponse {
  token: BearerToken;
}

export interface IdentityTokenBody {
  token: string;
}

export interface GoogleSignInBody {
  credential: string;
}

export enum LoginWithMethod {
  InviteEmail = "InviteEmail",
  // LinkedIn = 'LinkedIn',
  Google = "Google",
  Facebook = "Facebook",
  OnboardingToken = "OnboardingToken",
}

export interface ApiTokenPayloadData {
  uid: string;
  type: UserType;
  fullName: string;
  firstName: string;
  lastName: string;
  username: string | null;
  profilePictureURL: string;
  unverified?: true;
  isAdmin?: true;
  email: string;
  status: UserStatus;
  adminUser?: boolean;
}
export interface ApiTokenPayload extends ApiTokenPayloadData {
  v: 1;
}

export interface LoginWithCredentials {
  method: LoginWithMethod;
  payload: string;
}

export interface LoginResponse {
  token: ApiToken;
  user: CurrentUserObject | RegisteredUserObject;
}

export interface ErrorWithPayload extends Error {
  payload: AuthHydration["tokenPayload"];
}

export interface IpSessionTokenPayload {
  sessionId: string;
  expiresIn: number;
  ipAddress: string;
}

export interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}
