/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { TargeterUserCard, UserSearchRelevance } from "models/User";
import { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { Flex } from "views/Builder/components/Flex";

export const MatchedOn = ({
  builder,
}: {
  builder?: TargeterUserCard & { relevance: UserSearchRelevance };
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    container: {
      paddingTop: 1,
    },
    icon: {
      boxSizing: "border-box",
      paddingTop: 2,
    } as CSSObject,
  }));

  if (!builder) {
    return (
      <Flex gap={4}>
        <Skeleton width={16} />
        <Skeleton width={40} />
        <Skeleton width={40} />
        <Skeleton width={40} />
      </Flex>
    );
  }

  if (!builder?.relevance) {
    return null;
  }

  const {
    skills = [],
    roles = [],
    identifiableKeywords: keywords = [],
    cities = [],
    provinces = [],
    countries = [],
  } = builder.relevance;
  let matches: Array<string> = [];

  if (skills?.length) {
    matches.push(`${skills.join(", ")} (in Skills)`);
  }

  if (roles?.length) {
    matches.push(`${roles.join(", ")} (in Roles)`);
  }

  if (keywords?.length) {
    matches.push(`${keywords.join(", ")} (in Experience)`);
  }

  if ([...cities, ...provinces, ...countries].length) {
    matches.push("Location");
  }

  if (!matches.length) {
    return null;
  }

  // If matches string is too long, we can truncate it here
  let matchesTruncation = "";
  if (matches.join("").length > 60) {
    matches = matches.slice(0, 2);
    matchesTruncation = "...";
  }

  const isDesktop = useMinBreakpoint("sm");

  return (
    <div css={styles.container}>
      <Flex gap={isDesktop ? 4 : 8} alignItems="flex-start" wrap="nowrap">
        <div css={styles.icon}>
          <Icon size="md" name="recommended" />
        </div>
        <div>
          <Typography variant="textMedium" weight="bold" component="span">
            {`Matched on: `}
          </Typography>
          {matches.map((match, index) => (
            <Fragment key={index}>
              {index > 0 && ", "}
              <Typography
                component="a"
                color="Hannibal@600"
                key={index}
                onClick={(e) => e.stopPropagation()}
                rel="noopener noreferrer"
                target="_blank"
                variant="textMedium"
                style={{ display: "inline" }}
              >
                {match}
              </Typography>
            </Fragment>
          ))}
          {matchesTruncation}
        </div>
      </Flex>
    </div>
  );
};
