/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { Fragment } from "react";
import { Flex } from "views/Builder/components/Flex";
import { HorizontalDivider } from "views/Builder/components/HorizontalDivider";
import TrackLink from "components/TrackLink";

export type MetaData = [string | number | JSX.Element, { link?: string }?][];

export const MetaDataComponent = ({
  metaData,
  proposalId,
  source,
  isSampleProposal,
}: {
  metaData: MetaData;
  proposalId: string;
  source: "Builder Card" | "Builder Full";
  isSampleProposal: boolean;
}) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    link: {
      textDecoration: "none",
      color: colors.Hannibal[600],
      transition: "color 0.1s ease-in-out",
      "&:active, &:visited": {
        color: colors.Hannibal[600],
      },
      "&:hover": {
        color: colors.Hannibal[400],
      },
    },
  }));

  return (
    <Flex columnGap={8}>
      {metaData.map(([text, options], index) => (
        <Fragment key={index}>
          {options?.link && (
            <TrackLink
              name={`Proposal Builder Meta Data link`}
              eventProps={{
                link: options.link,
                linkType: text,
                source,
                proposalId,
                isSampleProposal,
              }}
              href={options.link}
              target="_blank"
              key={`builder--meta-data--${index}`}
              rel="noopener noreferrer"
              css={styles.link}
            >
              <Typography
                variant="textMedium"
                component="span"
                css={styles.link}
              >
                {text}
              </Typography>
            </TrackLink>
          )}
          {!options?.link &&
            (typeof text === "string" || typeof text === "number") && (
              <Typography
                variant="textMedium"
                component="div"
                key={`builder--meta-data--${index}`}
              >
                {text}
              </Typography>
            )}
          {index < metaData.length - 1 && (
            <HorizontalDivider key={`builder--meta-data--divider--${index}`} />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
