import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKeys from "queries/keys";
import { updatePublicUntil } from "services/proposals";
import { useRootStore } from "store";

interface UpdatePublicUntilPayload {
  proposalId: string;
  sharePublicly: boolean;
}

export const useUpdatePublicUntil = (
  onSuccess?: Function,
  onError?: Function
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ proposalId, sharePublicly }: UpdatePublicUntilPayload) =>
      updatePublicUntil(authStore, proposalId, { sharePublicly }),
    onSuccess: (data, { proposalId }) => {
      queryClient.setQueriesData(
        queryKeys.proposals.detail({ id: proposalId }).queryKey,
        data
      );
      onSuccess && onSuccess(data);
    },
    onSettled: ({ proposalId }) => {
      queryClient.invalidateQueries(
        queryKeys.proposals.detail({ id: proposalId }).queryKey
      );
    },
    onError: (error) => {
      setApiErrorToast(error, "Failed to update public status.");
      onError && onError(error);
    },
  });
};
