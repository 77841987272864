/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react";
import { commaAndJoin } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { findLastIndex, map } from "lodash";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import ExperimentDisclaimer from "views/Chat/components/Chat/ExperimentDisclaimer";
import Input from "views/Chat/components/Chat/Input";
import Message from "views/Chat/components/Chat/Message";
import StickyCta from "views/Chat/components/Chat/StickyCta";
import useMessageFunctions from "views/Chat/components/Chat/hooks/useMessageFunctions";
import { ChatMessage, MessageRole, MessageType } from "views/Chat/types";
export interface ChatProps {
  messageHandler?: (message: ChatMessage) => void;
  introMessages?: Array<Partial<ChatMessage>>;
  className?: string;
  embedded?: boolean;
  initailInput?: string;
  onInput?: (msg: string) => void;
  historyLoading?: boolean;
  disclaimerPosition?: "top" | "bottom" | "none";
}

const blink = keyframes`
0% {
  opacity: .1;
}
20% {
  opacity: 1;
}
100% {
  opacity: .1;
}
`;

const useStyles = ({ embedded }: ChatProps) => {
  return useCSSRulesWithTheme(({ colors }) => ({
    root: {
      paddingTop: 14,
      display: "flex",
      flexDirection: "column",
      width: 500,
      boxSizing: "border-box",
      height: `100vh`,
      overflow: "auto",
      borderRight: `1px solid ${colors.Grey[200]}`,
      "&::-webkit-scrollbar": {
        width: "0px",
        background: "transparent",
      },
      ...(embedded && {
        paddingLeft: 0,
        paddingRight: 0,
      }),
    } as const,
    input: {
      padding: "0px 16px 14px 16px",
      ...(embedded && {
        padding: 0,
      }),
    } as const,
    footer: {
      backgroundColor: colors.Grey[0],
      position: "sticky",
      bottom: -1,
      zIndex: 100,
    } as const,
    messages: {
      paddingTop: 8,
      paddingBottom: 8,
      display: "flex",
      flexDirection: "column",
      margin: "0 16px",
      flex: 1,
      justifyContent: "flex-end",
    } as const,
    typing: {
      height: 10,
      marginBottom: 10,
      position: "relative",
      span: {
        content: "''",
        animation: `${blink} 1s ease infinite`,
        animationFfillMode: "both",
        height: 10,
        width: 10,
        background: colors.Grey[300],
        position: "absolute",
        left: 0,
        top: 0,
        borderRadius: "50%",
        "&:nth-child(2)": {
          animationDelay: ".2s",
          marginLeft: 10 * 1.5,
        },
        "&:nth-child(3)": {
          animationDelay: ".4s",
          marginLeft: 10 * 3,
        },
      },
    } as const,
  }));
};

const TypingIndicator: FC = () => {
  const styles = useStyles({});
  return (
    <div css={styles.typing}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

const MessageHandlerProvider = createContext<{
  messageHandler: ChatProps["messageHandler"];
}>({
  messageHandler: () => {},
});

export const useChatMessageHandler = () =>
  useContext(MessageHandlerProvider).messageHandler;

const Chat: FC<ChatProps> = (props) => {
  const {
    messageHandler,
    introMessages,
    className,
    embedded,
    initailInput,
    onInput,
    historyLoading,
    disclaimerPosition = "top",
  } = props;
  const styles = useStyles(props);
  const execMessageSideEffects = useMessageFunctions();
  const chat = useChatContext();
  const { handleNewMessage, messages } = chat;
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const introduced = useRef(messages.length > 0);

  const onMessage = (text: string) => {
    const msg = handleNewMessage({ text });

    if (!messageHandler) {
      execMessageSideEffects(msg);
    } else {
      messageHandler(msg);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      messagesContainerRef.current?.scrollTo({
        top: (bottomRef.current?.offsetTop || 0) - 80,
        behavior: "smooth",
      });
    }, 100);

    return () => clearTimeout(timeout);
  }, [messages]);

  const isInThread = (index: number) => {
    if (index === 0) {
      return false;
    }
    const msg = messages[index];
    const prevMsg = messages[index - 1];

    if (!msg) {
      return false;
    }
    if (!prevMsg) {
      return false;
    }

    return prevMsg.role === msg.role;
  };

  const selectedForInterview = useMemo(() => {
    return chat.state.builders?.filter(
      ({ selectedForInterview }) => !!selectedForInterview
    );
  }, [chat.state.builders]);

  const inviteBuilders = useMemo(() => {
    if (!selectedForInterview || !selectedForInterview.length) {
      return undefined;
    }

    return `I want to interview ${commaAndJoin(
      map(selectedForInterview, "fullName")
    )}`;
  }, [selectedForInterview]);

  function postIntroMessage() {
    try {
      introMessages?.forEach((message) => {
        if (message.role === MessageRole.Bot) {
          chat.sendReply(message);
        } else if (message.role === MessageRole.User) {
          onMessage(message.text || "");
        }
      });
    } catch (error) {
      chat.handleNewMessage(error as ChatMessage);
    }
  }

  useEffect(() => {
    if (chat.state.transcriptId && !introduced.current) {
      postIntroMessage();
      introduced.current = true;
    }
  }, [chat.state.transcriptId, introduced]);

  const bottomRefPlacement = findLastIndex(
    messages,
    ({ component, type }) => !component && type === MessageType.text
  );

  return (
    <MessageHandlerProvider.Provider value={{ messageHandler }}>
      <div className={className} css={styles.root} ref={messagesContainerRef}>
        <div css={styles.messages}>
          {disclaimerPosition === "top" && <ExperimentDisclaimer />}
          {historyLoading ? (
            <Skeleton height={50} width={"80%"} count={3} inline={false} />
          ) : (
            <>
              {messages.map((msg, index) => (
                <>
                  {index === bottomRefPlacement && <div ref={bottomRef} />}
                  <Message
                    msg={msg}
                    key={msg.id}
                    thread={isInThread(index)}
                    avatarUrl={chat.state.avatarUrl}
                  />
                </>
              ))}
              {chat.loading && <TypingIndicator />}
            </>
          )}
        </div>
        <div css={styles.footer}>
          <div css={styles.input}>
            {chat.state.cta && <StickyCta {...chat.state.cta} />}
            <Input
              onInput={onInput}
              initialValue={initailInput}
              disabled={chat.loading || !chat.state.transcriptId}
              override={chat.inputOverride || inviteBuilders}
              onChange={onMessage}
              placeholder="Type your answer..."
              embedded={embedded}
              limitReached={chat.state.limitReached}
            />
          </div>
          {disclaimerPosition === "bottom" && (
            <ExperimentDisclaimer>
              This feature is experimental and may not always be precise
            </ExperimentDisclaimer>
          )}
        </div>
      </div>
    </MessageHandlerProvider.Provider>
  );
};

export default Chat;
