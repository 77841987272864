import { noop } from "lodash";
import { FC, createContext, useContext, useRef } from "react";

export interface NavStateItem<T> {
  ack(): void;
  value: T;
}

export interface NavStateContextProps {
  addNavState<T>(key: string, value: T): void;
  getNavState<T>(key: string): NavStateItem<T> | undefined;
}
export const NavStateContext = createContext<NavStateContextProps>({
  addNavState: noop,
  getNavState: () => undefined,
});

const NavStateProvider: FC = ({ children }) => {
  const state = useRef(new Map());

  function ackNavItem(key: string) {
    const newState = new Map(state.current);
    newState.delete(key);
    state.current = newState;
  }

  function addNavState<T>(key: string, value: T) {
    const newState = new Map(state.current);
    newState.set(key, value);
    state.current = newState;
  }

  function getNavState<T>(key: string) {
    return {
      ack: () => ackNavItem(key),
      value: state.current.get(key) as T,
    };
  }

  return (
    <NavStateContext.Provider
      value={{
        addNavState,
        getNavState,
      }}
    >
      {children}
    </NavStateContext.Provider>
  );
};

export default NavStateProvider;

export const useNavStateContext = () =>
  useContext<NavStateContextProps>(NavStateContext);
