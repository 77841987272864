/** @jsxImportSource @emotion/react */

import { Button, Modal, Tag, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useRef, useState } from "react";
import ReactPlayer from "react-player";
import aiIcon from "./assets/ai.svg";
import { generatePath, useNavigate } from "react-router-dom-v5-compat";
import { CuratorLocations } from "Locations";
import { useIsMobile } from "hooks/useIsMobile";

export interface TeamFormationAiPromoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  root: {
    width: "100%",
    maxWidth: 930,
    padding: 8,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: 0,
      maxHeight: 320,
      bottom: 0,
      top: "auto",
      left: 0,
      right: 0,
      transform: "none",
    },
  },
  video: {},
  playBtn: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 125,
    height: 80,
    cursor: "pointer",
    "&::before": {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: colors.Hannibal[600],
      content: '""',
      opacity: 0.9,
    },
    "&::after": {
      position: "absolute",
      left: "52%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      content: '""',
      borderLeft: "30px solid white",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
  grid: {
    display: "flex",
    gap: 40,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      flexDirection: "column",
    },
  },
  wrapper: {
    display: "inline-flex",
    position: "relative",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  pills: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingTop: 10,
    },
  },
  pillAi: {
    color: colors.Faceman[700],
    "& div": {
      width: 12,
      height: 12,
    },
    "& img": {
      width: 12,
      height: 12,
    },
  },
  pillNew: {
    color: colors.Hannibal[700],
  },
  description: {
    margin: "3px 0 24px",
  },
  btn: {
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      width: "100%",
    },
  },
});

export const TeamFormationAiPromoModal: FC<TeamFormationAiPromoModalProps> = (
  params
) => {
  const player = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(false);
  const navigate = useNavigate();
  const onPlay = () => {
    if (player.current) {
      setPlaying(true);
      player.current.seekTo(0);
    }
  };

  const { isOpen, onClose } = params;
  const styles = useCSSRulesWithTheme(getCSSRules);
  const onCtaClick = () => {
    onClose();
    const pathname = generatePath(CuratorLocations.Requirements, {
      type: "auth",
    });
    navigate({ pathname });
  };
  const isMobile = useIsMobile();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      css={styles.root}
      shouldHideGradientStroke={isMobile}
    >
      <div css={styles.grid}>
        <div css={styles.wrapper}>
          <ReactPlayer
            width={490}
            height={274}
            ref={player}
            controls={playing}
            muted={!playing}
            playing={true}
            playsinline
            css={styles.video}
            url={[
              "https://ucarecdn.com/66e7afb2-4f0c-4978-911b-756c93286d2e/",
              "https://ucarecdn.com/22910421-fb51-4550-9b6a-a8260b3f6a39/",
            ]}
          />
          {!playing && <div css={styles.playBtn} onClick={onPlay} />}
        </div>
        <div css={styles.sidebar}>
          <div css={styles.pills}>
            <Tag
              size="small"
              label="Team formation AI"
              color="Faceman@200"
              css={styles.pillAi}
              avatar={aiIcon}
            ></Tag>
            <Tag
              size="small"
              color="Hannibal@200"
              label="New"
              css={styles.pillNew}
            />
          </div>
          <Typography variant="textLarge" weight="bold">
            Use AI to find the talent you’ve been missing
          </Typography>
          <Typography
            variant="textMedium"
            component={"p"}
            css={styles.description}
          >
            Tell Team Formation AI what you're building or the roles you're
            hiring for, and watch it identify the ideal talent from A.Team's
            expert network based on their skills, experience, and compatibility.
            Want to know more about a candidate? Just ask their AI agent.
          </Typography>
          <Button onClick={onCtaClick} size="md" css={styles.btn}>
            Try it now
          </Button>
        </div>
      </div>
    </Modal>
  );
};
