import { UserId } from "@a_team/models/dist/UserObject";
import { TargeterUserCard } from "models/User";
import { ReactNode } from "react";
import { ChatTask } from "services/assistants";

export enum MessageRole {
  User = "user",
  Bot = "bot",
}

export interface ChatCta {
  body?: string;
  text?: string;
  action?: () => void;
  component?: ReactNode;
}

export enum MessageType {
  text = "text",
  action = "action",
  error = "error",
}

export interface ChatMessage {
  title?: string;
  text: string;
  html?: ReactNode;
  component?: React.ReactNode;
  id: string;
  timestamp: number;
  from: string;
  role: MessageRole;
  replyId?: string;
  type: MessageType;
  loading?: boolean;
  loadingText?: string;
  intro?: boolean;
  feedback?: boolean;
  confidence?: number;
  task?: ChatTask;
}

export interface ChatSpecRole {
  title: string;
  skills: Array<string>;
  description?: string;
  rid: string;
  isNew?: boolean;
  qty?: number;
  alternative?: boolean;
}

export interface Builder {
  uid: string;
  fullName: string;
  blurb: string;
  skills: Array<string>;
  roles: Array<string>;
  yearsOfExperience: number;
  numberOfMission: number;
  profilePicture?: string;
  qna: Array<{ questionKeywords: Array<string>; answer: string }>;
  companies: Array<string>;
  interviewDate?: Date;
  selectedForInterview?: boolean;
}

export interface ChatState {
  productType?: {
    name: string;
    roles: Array<ChatSpecRole>;
  };
  roles?: Array<ChatSpecRole>;
  alternativeRoles?: Array<ChatSpecRole>;
  existingRoles?: Array<ChatSpecRole>;
  cta?: ChatCta;
  builders?: Array<Builder>;
  recommendedBuilders?: Array<UserId>;
  selectedBuilder?: Builder;
  roleSearchIds?: Array<string>;
  updatedAt?: string;
  selectedForInterview?: Array<Builder>;
  scheduling?: boolean;
  hasCalendly?: boolean;
  schedulingWith?: Builder;
  threadId?: string;
  transcriptId?: string;
  avatarUrl?: string;
  messageFeedback?: string;
  stage?: string;
  missingCriteria?: ChatMissingCriteria;
  suggestedRoles?: Record<string, string>;
  limitReached?: boolean;
  interviewingBuilder?: TargeterUserCard;
}

export interface ChatMissingCriteria {
  categories?: boolean;
  industries?: boolean;
  roles?: boolean;
  skills?: boolean;
  location?: boolean;
  minYearsExperience?: boolean;
  hourlyRate?: boolean;
  experienceFromScratch?: boolean;
  managementExperience?: boolean;
  weeklyCommitment?: boolean;
}

export interface ProductType {
  name: string;
  roles: Array<ChatSpecRole>;
}
