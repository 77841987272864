/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { Icon, Typography } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { openInNewTab } from "helpers/url";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useMemo } from "react";
import Calendar from "./assets/calendar.svg";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 24,
    borderRadius: 6,
    boxSizing: "border-box",
    paddingTop: 100,
    paddingBottom: 100,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingTop: "unset",
      paddingBottom: "unset",
      border: "1px solid #E5E7EB",
    },
  },
  calendar: {
    width: 64,
    height: 64,
  },
  text: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: 480,
    },
  },
  button: {
    position: "relative",
    paddingLeft: 16,
    paddingRight: 24,
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: "unset",
    },
  },
  external: {
    position: "absolute",
    transform: "rotate(135deg)",
    right: 4,
    top: 4,
  },
  alert: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    backgroundColor: colors.Hannibal[100],
    color: colors.Grey[800],
    fontSize: 12,
    padding: 12,
    borderRadius: 4,
  },
});

export interface NonNativeBookingProps {
  builderProfile?: UserObject;
}

const NonNativeBooking: FC<NonNativeBookingProps> = ({ builderProfile }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const calendarLink = useMemo(
    () => builderProfile?.customCalendar?.calendarLink,
    [builderProfile]
  );

  const onBookInterview = () => {
    if (!calendarLink) {
      return;
    }
    openInNewTab(calendarLink);
  };

  return (
    <div css={styles.container}>
      <img src={Calendar} css={styles.calendar} alt="calendar" />
      <div css={styles.text}>
        <Typography variant="textLarge" weight="bold">
          Book an interview with {builderProfile?.fullName}
        </Typography>
        <Typography variant="textMedium">
          {builderProfile?.fullName} uses a 3rd party calendar link not directly
          hosted on A.Team. Follow the link to schedule an interview.
        </Typography>
      </div>
      <TrackButton
        size="md"
        name="Book an interview (non-native)"
        eventProps={{ calendarLink }}
        disabled={!calendarLink}
        onClick={onBookInterview}
        css={styles.button}
      >
        Book an interview
        <Icon name="back" css={styles.external} color="Grey@0" size="md" />
      </TrackButton>
      {!calendarLink && (
        <div css={styles.alert}>
          <Icon name="details" color="Hannibal@600" size="md" />
          <span>
            This builder cannot be booked because they have not shared their
            calendar link.
          </span>
        </div>
      )}
    </div>
  );
};

export default NonNativeBooking;
