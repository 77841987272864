export enum GTMEvents {
  TFAIBuilderShortlisted = "TFAI - Builder shortlisted",
  TFAIChatMessageSent = "TFAI - Chat message sent",
  TFAISpecSubmitted = "TFAI - Spec submitted",
  TFAISignup = "TFAI Sign-up",
}

class TagManager {
  private dataLayer: any & { push: () => void };

  constructor() {
    this.dataLayer = window.dataLayer;
  }

  public push(args: { event: GTMEvents; [key: string]: any }) {
    if (this.dataLayer) {
      this.dataLayer.push(args);
    }
  }
}

const tagManager = new TagManager();

export default tagManager;
