import { type RegisteredUserObject } from "@a_team/models/dist/UserObject";
import { logger } from "@sentry/utils";
import analytics from "analytics";
import { Flags } from "configs/featureFlags";
import { getUserDisplayName } from "helpers/strings";
import { getBaseSpecificity } from "hooks/useQueryKeyAuth";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { FeatureFlagEntry } from "models/FeatureFlag";
import Store from "models/Store";
import { useSignOutHookSafe } from "queries/auth/useSignOut";
import queryKeys from "queries/keys";
import { getCurrentUser } from "services/auth";
import { updateUserPersonalDetails } from "services/user";
import { RootStore } from "store";

export type UserHydration = {
  user: any; //TODO: import models
};

export interface CookieManager {
  get(key: string): string | undefined;
  set(
    key: string,
    value: string,
    opts: { expires?: Date; domain?: string }
  ): void;
  remove(key: string, opts?: { domain?: string }): void;
}

export class UserStore implements Store {
  rootStore: RootStore;
  @observable public user?: RegisteredUserObject;

  public constructor(rootStore: RootStore, initialData?: UserHydration) {
    this.rootStore = rootStore;

    if (initialData) {
      this.user = initialData.user;
    }

    makeObservable(this);
  }

  public loadCurrentUser() {
    return this.updateUser();
  }

  // Override means replace with payload instead of merge
  public async updateUser(override?: boolean) {
    const hasToken = !!this.rootStore.authStore.token;
    if (hasToken) {
      try {
        const user = await getCurrentUser(this.rootStore.authStore);
        return this.setUser(user, override);
      } catch (error: unknown) {
        // Prevents the infinite loading screen when the user is not authenticated
        // but has a token and updateUser keeps getting called
        if ([401, 404].includes((error as { status?: number })?.status ?? 0)) {
          useSignOutHookSafe(this.rootStore);
        }
      }
    }
  }

  @action public setUser = (
    payload: RegisteredUserObject,
    replace?: boolean
  ): void => {
    this.user = replace
      ? payload
      : {
          ...this.user,
          ...payload,
        };

    if (this.user.uid) {
      analytics.identify(this.user.uid, payload);
    }
  };

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }
  @computed public get displayName(): string {
    return this.user ? getUserDisplayName(this.user) : "";
  }

  @computed public get isAdmin(): boolean {
    if (!this.user) {
      return false;
    }

    return !!this.user.isAdmin;
  }

  /**
   *
   *
   * @param {string} schedulingLink
   * @memberof UserStore
   * @returns {Promise<void>}
   */
  public setSchedulingLink = async (
    schedulingLink: string
  ): Promise<boolean> => {
    try {
      if (this.isAdmin) {
        return false;
      }

      const promise = updateUserPersonalDetails(this.rootStore.authStore, {
        schedulingLink,
      });

      promise.catch((err) =>
        this.rootStore.uiStore.setApiErrorToast(
          err,
          "Failed to update user details"
        )
      );

      promise.then(({ user }) => this.setUser(user));
      await promise;

      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  };

  public flagOpenForUser(name: Flags): boolean {
    const specificity = getBaseSpecificity(
      this.rootStore.authStore.token,
      this.rootStore.accountsStore.currentAccountId
    );

    const queryKey = queryKeys.featureFlags.list(specificity).queryKey;

    const featureFlags = this.rootStore.queryClient.getQueryData(
      queryKey
    ) as FeatureFlagEntry[];

    return !!(featureFlags || []).find((flag) => flag.name === name)?.isOpen;
  }
}
