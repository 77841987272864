import ContractObject, {
  ContractPartyObject,
  ContractPartyType,
  ContractStatus,
} from "@a_team/models/dist/ContractObject";
import { RegisteredUserObject, UserType } from "@a_team/models/dist/UserObject";
import config from "config";
import { AccountId } from "models/Account";

const WEEKS_PER_MONTH = 4.33;
const HOURS_PER_WEEK = 40;

export interface EnrichedContractObject extends ContractObject {
  clientParty?: ContractPartyObject;
  builderParty?: ContractPartyObject;
  canSign?: boolean;
}

export const canSignContract = (
  contract: ContractObject,
  user?: RegisteredUserObject,
  missionAccountId?: AccountId
): boolean => {
  // @TODO Enforce these rules on the backend with shared library

  // A.Team admins are allowed to sign contract for A.Team missions
  if (
    user?.isAdmin &&
    missionAccountId &&
    config.REACT_APP_ATEAM_ACCOUNT_ID &&
    missionAccountId === config.REACT_APP_ATEAM_ACCOUNT_ID
  ) {
    return true;
  }

  if (
    !user ||
    contract.custom ||
    user?.isAdmin ||
    user?.type !== UserType.CompanyUser
  ) {
    return false;
  }

  return contract.parties.some(
    ({ type, signedAt }) =>
      !signedAt && type === ContractPartyType.BillingCustomer
  );
};

export const getContractStatusLabel = (
  contract: EnrichedContractObject
): string => {
  const { status, custom, clientParty } = contract;
  const signed = status === ContractStatus.Completed;

  let label: string;
  if (custom) {
    label = "Uploaded";
  } else if (signed) {
    label = "Signed"; // all parties have signed
  } else if (clientParty?.signedAt) {
    label = "Waiting on builder"; // client has signed and builder has not
  } else {
    label = "Not signed"; // client needs to sign
  }

  return label;
};

export const monthlyRateToHourlyRate = (monthlyRate: number): number => {
  return monthlyRate / HOURS_PER_WEEK / WEEKS_PER_MONTH;
};
