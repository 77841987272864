import { useLocalStorage } from "hooks/useLocalStorage";
import { useRootStore } from "store";

export function useTfAiPromoModalState() {
  const { authStore } = useRootStore();
  const { ipSessionId } = authStore;
  const autoHide = !!ipSessionId || !!window?.Cypress;

  const [hideModal, setHideModal] = useLocalStorage(
    "hide_TF_AI_promo_modal",
    autoHide
  );

  return [hideModal, setHideModal];
}
