import { useMutation } from "@tanstack/react-query";
import { AuthObject, ErrorWithPayload } from "models/Auth";
import { registerUserWithGoogle } from "services/auth";
import { useRootStore } from "store";
import { useRegisterContext } from "views/Register/Context";

export const useGoogleRegister = () => {
  const { authStore } = useRootStore();
  const { finishSignInWithAuthObject, setAccountToken } = authStore;
  const { setCompanyId } = useRegisterContext();

  return useMutation<AuthObject, ErrorWithPayload, string>({
    mutationFn: (credential) => registerUserWithGoogle(credential),
    onSuccess: (data) => {
      finishSignInWithAuthObject.bind(authStore)(data);
      if (data.accountToken) {
        setAccountToken.bind(authStore)(data.accountToken);
      }
      if (data.companyId) {
        setCompanyId(data.companyId);
      }
    },
  });
};
