/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { RegisterLocation, SigninLocation } from "Locations";
import TrackModal from "components/TrackModal";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useRedirectOrNavigate } from "hooks/useRedirectOrNavigate";
import { FC, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import Credentials from "views/Register/Credentials";
import { ReactComponent as AteamLogo } from "./assets/ateam-logo.svg";

export interface SignupModalProps {
  isOpen: boolean;
  title: string;
  onClose: (formSubmitted?: boolean) => void;
  onRegister?: () => void;
}

const cssRules: CSSRulesResolver = () => ({
  modal: {
    overflow: "hidden",
    maxWidth: 432,
    boxSizing: "border-box",
    borderRadius: 12,
    padding: 16,
    paddingBottom: 0,
  },
  logo: {
    marginBottom: 9,
  },
  footer: {
    paddingTop: 23,
    textAlign: "center",
  },
});

const SignupModal: FC<SignupModalProps> = ({
  isOpen,
  title,
  onClose,
  onRegister,
}) => {
  const styles = useCSSRulesWithTheme(cssRules);
  const redirect = useRedirectOrNavigate();
  const navigate = useNavigate();
  const header = (
    <>
      <AteamLogo css={styles.logo} />
      <Typography weight="bold" variant="textLarge">
        {title}
      </Typography>
    </>
  );

  const onSubmit = () => {
    onRegister && onRegister();
    navigate(RegisterLocation);
    onClose(true);
  };

  const handleLogin: MouseEventHandler = (e) => {
    e.preventDefault();
    onClose(true);
    redirect(SigninLocation);
  };

  return (
    <TrackModal
      trackOnceOnMountAndUnmount
      isOpen={isOpen}
      css={styles.modal}
      onClose={() => onClose()}
      name="Signup Modal"
    >
      <>
        <Credentials header={header} onSubmit={onSubmit} />
        <div css={styles.footer}>
          <Typography align="center" variant="textMedium">
            Already have an account?{" "}
            <Typography
              component={"a"}
              href="#"
              weight="bold"
              color="Hannibal@600"
              variant="textMedium"
              onClick={handleLogin}
            >
              Sign in
            </Typography>
          </Typography>
        </div>
      </>
    </TrackModal>
  );
};

export default SignupModal;
