//TODO: get rid of querystring dependency
import { upperCase } from "lodash";
import qs, { ParsedUrlQueryInput } from "querystring";
import { AuthStore } from "store/Auth";
import config from "config";
import { ErrorCodes } from "models/ApiError";

export const API_SERVICE_BASE_PATH =
  config.REACT_APP_ATEAM_API_URL || "https://client-api-sandbox.a.team/";

export type ServiceFetchParams = Parameters<typeof serviceFetchParameters>;
export interface ServiceError extends Error {
  code?: number;
  payload?: unknown;
}

export type ServiceFetchMethod = "get" | "post" | "put" | "delete" | "patch";

type ServiceCredentials = "include" | "same-origin" | "omit";
type ServiceRedirect = "manual" | "follow" | "error";
type ServiceMode = "no-cors" | "cors" | "same-origin";
type ServiceReferrerPolicy =
  | "no-referrer"
  | "no-referrer-when-downgrade"
  | "origin"
  | "origin-when-cross-origin"
  | "same-origin"
  | "strict-origin"
  | "strict-origin-when-cross-origin"
  | "unsafe-url";

interface ServiceOptions {
  credentials?: ServiceCredentials;
  redirect?: ServiceRedirect;
  mode?: ServiceMode;
  referrerPolicy?: ServiceReferrerPolicy;
  signal?: AbortSignal;
  refreshRetry?: boolean;
}

export function parseServiceFetchError(
  res: Response,
  params: ServiceFetchParams
): Promise<never> {
  const [auth, path] = params;

  if (auth && res.status === 401) {
    auth.invalidate();
  }

  if (!res.ok) {
    return res.json().then((err) => {
      return Promise.reject({ status: res.status, ...err });
    });
  }

  return res
    .json()
    .catch((): never => {
      throw new Error(`Invalid response status ${res.status} at: "${path}"`);
    })
    .then((body: any): never => {
      const err: ServiceError = new Error(
        body.error
          ? body.error.message
            ? String(body.error.message)
            : String(body.error)
          : String(body.message) || JSON.stringify(body)
      );

      err.code = body.error.code;
      err.payload = body.payload || "";
      throw err;
    });
}

export function serviceFetchParameters(
  auth: AuthStore | null,
  path: string,
  query: ParsedUrlQueryInput | null = null,
  method: ServiceFetchMethod = "get",
  body?: unknown,
  headers?: object,
  options?: ServiceOptions
): [string, RequestInit] {
  const fetchOpts: RequestInit & {
    headers: Record<string, string>;
  } & ServiceOptions = {
    method: upperCase(method), // There's a bug somewhere in the HTTP stack that doesn't recognize "patch" if it's lowercase. Super weird
    headers: {},
    ...(options || {}),
  };

  if (auth) {
    auth.bearerToken &&
      (fetchOpts.headers["Authorization"] = `Bearer ${auth.bearerToken}`);
    auth.accountToken &&
      (fetchOpts.headers["x-account-token"] = auth.accountToken);
    auth.ipSessionToken &&
      (fetchOpts.headers["x-session-token"] = auth.ipSessionToken);
  }

  // Extend our headers if needed
  if (headers) {
    fetchOpts.headers = { ...fetchOpts.headers, ...headers };
  }

  if (body) {
    fetchOpts.headers["Content-Type"] = "application/json";
    fetchOpts.body = JSON.stringify(body);
  }

  const url = query ? `${path}?${qs.stringify(query)}` : path;

  return [API_SERVICE_BASE_PATH + url, fetchOpts];
}
function serviceFetch<T>(...params: ServiceFetchParams): Promise<T> {
  return fetch(...serviceFetchParameters(...params)).then(
    (res: Response): Promise<T> => {
      const [auth] = params;

      if (auth) {
        auth.processResponseHeaders(res.headers);
      }

      if (res.ok) {
        return res.json();
      }

      return parseServiceFetchError(res, params);
    }
  );
}

export default function serviceFetchWithRetry<T>(
  ...params: ServiceFetchParams
): Promise<T> {
  return serviceFetch<T>(...params).catch((err) => {
    if (err.status === 403 && err.code === ErrorCodes.LOGIN_TOKEN_EXPIRED) {
      const [auth] = params;

      if (auth) {
        return auth.renewToken().then(() => {
          return serviceFetch<T>(...params);
        });
      }

      return serviceFetch<T>(...params);
    }

    throw err;
  });
}
