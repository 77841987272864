import TrackButton from "components/TrackButton";
import { WarningStrip } from "components/WarningStrip";
import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useHasMissions from "hooks/useHasMissions";
import { observer } from "mobx-react";
import { useBillingInfo } from "queries/billingInfo/useBillingInfo";
import { FC, useMemo } from "react";
import { useRootStore } from "store";

export const MissingBillingInfoWarning: FC<{ sidebar?: boolean }> = observer(
  ({ sidebar }) => {
    const {
      userStore: { isAdmin },
      billingInformationStore: { setModalOpen },
    } = useRootStore();

    const { isLoading, hasMissions } = useHasMissions();
    const { data: billingInfo } = useBillingInfo();
    const { flagEnabled: showClientBillingBanner } = useFeatureFlag(
      Flags.ShowClientBillingBanner
    );

    const isBillingInfoMissing = useMemo(() => {
      if (!hasMissions || isLoading) {
        return false;
      }

      if (billingInfo === undefined) {
        // skip `undefined` values, accept only nulls or partial info
        return false;
      }

      return (
        !isAdmin &&
        (!billingInfo || !billingInfo.contactName || !billingInfo.address)
      );
    }, [billingInfo, isLoading, hasMissions]);

    if (!isBillingInfoMissing || !showClientBillingBanner) {
      return null;
    }

    return (
      <WarningStrip
        title="Missing billing information"
        actions={
          <TrackButton
            name="Add billing information"
            variant="secondary"
            size="sm"
            onClick={() => setModalOpen(true)}
          >
            Add billing information
          </TrackButton>
        }
        withSidebar={sidebar}
        dataTestingId="missing-billing-info-warning"
      >
        Please update your billing information so we can generate invoices.
      </WarningStrip>
    );
  }
);
