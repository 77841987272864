import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useQuery } from "@tanstack/react-query";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { getProposal } from "services/proposals";

const useProposal = (id: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ id });

  const { queryKey } = queryKeys.proposals.detail(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getProposal(authStore, id!),
    enabled: !!id,
    onError: (error) => setApiErrorToast(error, "Failed to get proposal", 3000),
    cacheTime: 0,
  });
};

export default useProposal;
