/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { ToBuilderLocation } from "Locations";
import { BookInterviewButton } from "components/BookInterviewButton";
import ChatInputCta from "components/DiscoveryCards/ChatInputCta";
import FeatureFlag from "components/FeatureFlag";
import FloatingWindowProvider from "components/FloatingWindowProvider";
import { StarButton } from "components/StarButton";
import { Flags } from "configs/featureFlags";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import useIsVisble from "hooks/useIsVisible";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { TargeterUserCard, UserSearchRelevance } from "models/User";
import useTrackBuilderImpression from "queries/discover/useTrackBuilderImpression";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { BuilderResultAbout } from "views/Browse/Search/BuilderResultAbout";
import { BuilderResultMetaData } from "views/Browse/Search/BuilderResultMetaData";
import { BuilderResultSkills } from "views/Browse/Search/BuilderResultSkills";
import { Divider } from "views/Browse/Search/Divider";
import { MatchedOn } from "views/Browse/Search/MatchedOn";
import DefaultAvatar from "views/Builder/assets/default-avatar.png";
import { Flex } from "views/Builder/components/Flex";

const BuilderResult = ({
  builder,
  divider = true,
  role,
}: {
  builder?: TargeterUserCard & { relevance: UserSearchRelevance };
  divider?: boolean;
  role?: string;
}) => {
  const { uid } = builder ?? {};

  const avatarUrl = useBuilderAvatar(
    builder?.profilePictureURL ?? DefaultAvatar
  );

  const context = { page: "browse_search" };
  const { mutate: trackImpression, cancelMutation } = useTrackBuilderImpression(
    builder?.uid,
    context
  );

  const styles = useCSSRulesWithTheme(
    ({ colors, breakpoints }) =>
      ({
        avatar: {
          [`@media (min-width: ${breakpoints.sm}px)`]: {
            width: 72,
            height: 72,
          },
        },
        linkOverlay: {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        container: {
          position: "relative",
          boxSizing: "border-box",
          cursor: "pointer",
          transition: "all 0.4s ease-in-out",
          width: "auto",
          borderRadius: 5,
          marginTop: 16,
          marginBottom: 16,
          "&:hover": {
            boxShadow: `0 0 0 16px ${colors.Grey[100]}`,
            backgroundColor: colors.Grey[100],
          },
          [`@media (min-width: ${breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
          },
        },
        name: {
          boxSizing: "border-box",
          paddingBottom: 8,
          [`@media (min-width: ${breakpoints.sm}px)`]: {
            paddingBottom: 3,
          },
        },
        buttons: {
          flexGrow: 1,
          flexShrink: 0,
        },
        divider: {
          paddingBottom: 16,
          paddingTop: 16,

          [`@media (min-width: ${breakpoints.sm}px)`]: {
            marginLeft: 88,
          },
        },
        builderActionButton: {
          position: "relative",
          zIndex: 2,
        },
        chatInputCta: {
          padding: "12px 0 5px",
          maxWidth: "85%",
        },
      } as CSSObject)
  );

  const isDesktop = useMinBreakpoint("sm");
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisble(ref);

  useEffect(() => {
    if (!isVisible || !builder?.uid) {
      return;
    }

    trackImpression();

    return cancelMutation;
  }, [isVisible, builder?.uid]);

  return (
    <FloatingWindowProvider>
      <div css={styles.container} ref={ref}>
        {uid && (
          <Link
            css={styles.linkOverlay}
            to={ToBuilderLocation(uid)}
            target="_blank"
          />
        )}
        <Flex gap={16} alignItems="flex-start">
          {isDesktop &&
            (builder ? (
              <Avatar size="xl" src={avatarUrl} css={styles.avatar} />
            ) : (
              <Skeleton borderRadius="37.5%" width={72} height={72} />
            ))}
          <div style={{ flex: 1 }}>
            <div css={styles.name}>
              <Flex
                justifyContent="space-between"
                alignItems="flex-start"
                wrap="nowrap"
              >
                <Flex gap={12}>
                  {!isDesktop &&
                    (builder ? (
                      <Avatar size="lg" src={avatarUrl} />
                    ) : (
                      <Skeleton borderRadius="37.5%" width={48} height={48} />
                    ))}
                  <div>
                    {builder ? (
                      <Typography variant="textLarge" component="h3">
                        {builder.fullName}
                      </Typography>
                    ) : (
                      <Skeleton />
                    )}
                    {builder ? (
                      <Typography variant="textMedium" component="div">
                        {role ?? builder.role?.title}
                      </Typography>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </Flex>
                <Flex gap={4}>
                  <FeatureFlag on name={Flags.DirectInterviewBooking}>
                    {builder ? (
                      <BookInterviewButton
                        disabled={builder.isUsingSharedCalendar}
                        user={builder}
                        css={styles.builderActionButton}
                      />
                    ) : (
                      <Skeleton width={100} height={40} />
                    )}
                  </FeatureFlag>
                  {builder ? (
                    <StarButton
                      builder={builder}
                      size="small"
                      css={styles.builderActionButton}
                      context={context}
                    />
                  ) : (
                    <Skeleton width={40} height={40} />
                  )}
                </Flex>
              </Flex>
            </div>
            <BuilderResultMetaData role={role} builder={builder} />
            <BuilderResultAbout builder={builder} />
            <BuilderResultSkills builder={builder} />
            <MatchedOn builder={builder} />
            {builder ? (
              <div css={styles.chatInputCta}>
                <FeatureFlag name={Flags.AiChatBuilderCardCta} on>
                  <ChatInputCta builder={builder} />
                </FeatureFlag>
              </div>
            ) : (
              <Skeleton width="85%" height={40} />
            )}
          </div>
        </Flex>
      </div>
      {divider && (
        <div css={styles.divider}>
          <Divider />
        </div>
      )}
    </FloatingWindowProvider>
  );
};

export default BuilderResult;
