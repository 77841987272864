/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import { imageFit } from "@styles";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { CSSProperties } from "react";

export interface NameAndAvatarProps {
  acceptedInvite?: boolean;
  email: string;
  fullName: string;
  profilePictureURL: string;
  indicateIsSelf?: boolean;
  avatarSize?: "sm" | "lg";
  style?: CSSProperties;
}

const getCSSRules: CSSRulesResolver<NameAndAvatarProps> = ({
  colors,
  avatarSize,
  style,
}) => ({
  collaborator: {
    display: "flex",
    alignItems: "center",
    ...style,
  },
  avatar: {
    height: avatarSize === "lg" ? 32 : 24,
    width: avatarSize === "lg" ? 32 : 24,
    borderRadius: "9px",
    backgroundColor: colors.Hannibal[500],
    display: "block",
  },
  avatarImage: {
    height: avatarSize === "lg" ? 32 : 24,
    width: avatarSize === "lg" ? 32 : 24,
    marginRight: 12,
    img: { ...imageFit },
  },
  indicator: {
    color: colors.Grey[500],
  },
});

export const NameAndAvatar = (props: NameAndAvatarProps) => {
  const {
    acceptedInvite = true,
    email,
    fullName,
    profilePictureURL,
    indicateIsSelf,
  } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);

  return (
    <>
      <div css={styles.collaborator}>
        <div>
          <Avatar
            css={styles.avatarImage}
            size={"sm"}
            src={profilePictureURL}
            placeholderVariant="purple"
          />
        </div>
        <div>
          <Typography
            color={!acceptedInvite ? "Grey@500" : "Grey@900"}
            component="div"
            variant="textMedium"
          >
            <span>{fullName}</span>
            {indicateIsSelf && <span css={styles.indicator}> (you)</span>}
            {!acceptedInvite && <span css={styles.indicator}> (pending)</span>}
          </Typography>
          <Typography variant="textSmall" color={"Grey@500"} component="div">
            {email}
          </Typography>
        </div>
      </div>
    </>
  );
};
