import { Button } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import { useIsDirectSchedulingEnabled } from "hooks/useIsDirectSchedulingEnabled";
import { useRootStore } from "store";

export const InterviewButton = ({
  onClick,
  selected,
}: {
  onClick: () => void;
  selected: boolean;
}) => {
  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  const directSchedulingEnabled = useIsDirectSchedulingEnabled();
  const isSmallScreen = useBelowBreakpoint("sm");

  const buttonName = directSchedulingEnabled
    ? "Book an interview"
    : "Select for interview";

  if (!isLoggedIn) {
    return null;
  }

  if (selected && !directSchedulingEnabled) {
    return (
      <Button
        iconProps={{ name: "statusPositiveNoBorder" }}
        variant="positive"
        size="sm"
        style={{
          cursor: "default",
          height: 32,
          paddingLeft: 16,
          paddingRight: 16,
          width: "100%",
        }}
      >
        Selected
      </Button>
    );
  }

  return (
    <TrackButton
      name={buttonName}
      variant="main"
      size={isSmallScreen ? "md" : "sm"}
      onClick={onClick}
      width="auto"
      iconProps={selected ? { name: "statusPositiveNoBorder" } : undefined}
      style={{ width: "100%" }}
    >
      {buttonName}
    </TrackButton>
  );
};
