/** @jsxImportSource @emotion/react */

import { FunctionComponent } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import { useRootStore } from "store";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

export const DEFAULT_TOAST_DURATION = 5000;

const getCSSRules: CSSRulesResolver<{ isMobile: boolean }> = ({
  isMobile,
}) => ({
  toastContainer: {
    zIndex: "10003",
    width: isMobile ? "100%" : "auto",
    "& .Toastify__toast": {
      minHeight: 32,
      fontSize: 14,
      lineHeight: "20px",
      padding: "4px 8px 4px 4px",
      borderRadius: isMobile ? 0 : 4,
      width: "auto",
      background: "#35373a",
      fontFamily: "inherit",
    },
    "& .Toastify__toast .Toastify__toast-body div": {
      width: "auto",
    },
    "& .Toastify__toast .Toastify__toast-icon": {
      width: 16,
    },
  },
});

const ToastProvider: FunctionComponent = ({ children }) => {
  const {
    uiStore: { isMobile },
  } = useRootStore();

  const styles = useCSSRulesWithTheme(getCSSRules, { isMobile });

  return (
    <>
      {children}
      <ToastContainer
        position="bottom-right"
        hideProgressBar
        containerId={"main-container"}
        enableMultiContainer
        pauseOnFocusLoss
        pauseOnHover
        autoClose={DEFAULT_TOAST_DURATION}
        transition={Zoom}
        css={css(styles.toastContainer)}
      />
    </>
  );
};

export default ToastProvider;
